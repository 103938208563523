import {Button, Form, Input, Modal, Space} from 'antd';
import {FC} from 'react';
import Loading from '@app/components/Loading/Loading';
import {useTranslation} from 'react-i18next';
import {usePharmacyForm} from './usePharmacyForm';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';

interface IPharmacyInviteProps {
  open: boolean;
  onCancel: () => void;
}

const PharmacyInvite: FC<IPharmacyInviteProps> = ({open, onCancel}) => {
  const {t} = useTranslation();
  const {onInvite, form, isLoading} = usePharmacyForm({onCancel});
  const {emailRules} = useFormValidationRules();
  const inputMaxRules = useInputMaxLength();

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={t('Send a pharmacy request')}
      footer={null}
    >
      {isLoading && <Loading />}
      <div style={{textAlign: 'center'}}>
        <Form
          form={form}
          labelCol={{span: 8, offset: 2}}
          wrapperCol={{span: 12}}
          labelAlign="left"
          autoComplete="off"
          onFinish={onInvite}
        >
          <Space direction="vertical" style={{width: '100%'}} size={24}>
            <div>
              {t(
                'Please send an email to the pharmacy admin and request initial registration.',
              )}
            </div>
            <Form.Item
              name={['manager', 'email']}
              label={t('Pharmacy admin email')}
              rules={emailRules()}
              style={{marginBottom: 0}}
            >
              <Input
                maxLength={inputMaxRules.email}
                placeholder={t('Placeholders.Admin email')}
              />
            </Form.Item>
            <div>
              {t(
                'Alternatively, self-registration is possible through pharmacy registration.',
              )}
            </div>
            <Button type="primary" htmlType="submit">
              {t('Request pharmacy registration via email')}
            </Button>
          </Space>
        </Form>
      </div>
    </Modal>
  );
};

export default PharmacyInvite;
