import {usePharmacyResendEmailMutation} from '@app/redux/services/pharmacyService';
import {TPharmacy} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {TableProps} from 'antd';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

interface IUsePharmacyListState {
  selectedPharmacyId: number | undefined;
  isDetailModalOpen: boolean;
  isInviteModalOpen: boolean;
}

interface IUsePharmacyListActions {
  onDetailModelOpen: (pharmacyId?: number) => void;
  onDetailModalClose: () => void;
  onInviteModalOpen: () => void;
  onInviteModalClose: () => void;
  onTableChange: TableProps<TPharmacy>['onChange'];
  resend: (id: number) => Promise<void>;
}

export const usePharmacyList = (): [
  IUsePharmacyListState,
  IUsePharmacyListActions,
] => {
  const {t} = useTranslation();
  const [state, setState] = useState<IUsePharmacyListState>({
    selectedPharmacyId: undefined,
    isDetailModalOpen: false,
    isInviteModalOpen: false,
  });

  const [, setSearchParams] = useSearchParams();
  const [resendEmail] = usePharmacyResendEmailMutation();

  const onTableChange: TableProps<TPharmacy>['onChange'] = (pagination) => {
    const {current, pageSize} = pagination;
    setSearchParams({
      page: String(current),
      limit: String(pageSize),
    });
  };

  const onDetailModelOpen = useCallback(
    (pharmacyId?: number) => {
      setState((prevState) => ({
        ...prevState,
        selectedPharmacyId: pharmacyId,
        isDetailModalOpen: true,
        isInviteModalOpen: false,
      }));
    },
    [setState],
  );

  const onDetailModalClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      selectedPharmacyId: undefined,
      isDetailModalOpen: false,
    }));
  }, [setState]);

  const onInviteModalOpen = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isDetailModalOpen: false,
      isInviteModalOpen: true,
    }));
  }, [setState]);

  const onInviteModalClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isInviteModalOpen: false,
    }));
  }, [setState]);

  const resend = async (id: number) => {
    try {
      await resendEmail({id: id}).unwrap();
      showNotification(
        t('Success'),
        t('Email has been sent.'),
        'bottomRight',
        'success',
      );
    } catch (error) {
      showNotification(
        t('Error'),
        t('An error occurred while resending the email.'),
        'bottomRight',
        'error',
      );
    }
  };

  const actions: IUsePharmacyListActions = {
    onDetailModelOpen,
    onDetailModalClose,
    onInviteModalOpen,
    onInviteModalClose,
    onTableChange,
    resend,
  };

  return [state, actions];
};
