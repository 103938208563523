import useGeolocation from '@app/hooks/useGeolocation';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';
import {Card, Col, Form, Input, Row} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface IPharmacyInfoCardProps {
  form?: FormInstance;
  isDisabled?: boolean;
  isFieldsRequired?: boolean;
  extra?: JSX.Element;
  columnLayout?: {[key: string]: number};
}

const PharmacyInfoCard: FC<IPharmacyInfoCardProps> = ({
  form,
  isDisabled = false,
  isFieldsRequired = true,
  extra,
}) => {
  const {t} = useTranslation();
  const isRequired = isDisabled ? false : isFieldsRequired;
  const {isLoading, onSearch} = useGeolocation();
  const {requiredOnly, facilityCodeRules, phoneNumberRules, zipCodeRules} =
    useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  const onSearchAddress = async (value: string) => {
    const result = await onSearch(value);
    form?.setFieldsValue({
      pharmacy: {
        location: result,
      },
    });
  };

  const FormItems = {
    name: (
      <Form.Item
        name={['pharmacy', 'name']}
        label={t('Pharmacy name')}
        rules={requiredOnly({isRequired})}
      >
        <Input
          maxLength={inputMaxLength.pharmacyName}
          placeholder={isDisabled ? '' : t('Placeholders.Pharmacy name')}
          disabled={isDisabled}
        />
      </Form.Item>
    ),
    facilityCode: (
      <Form.Item
        name={['pharmacy', 'facility_code']}
        label={t('Medical facility code')}
        rules={facilityCodeRules({isRequired})}
      >
        <Input
          maxLength={inputMaxLength.facilityCode}
          placeholder={
            isDisabled ? '' : t('Placeholders.Medical facility code')
          }
          disabled={isDisabled}
        />
      </Form.Item>
    ),
    phoneNumber: (
      <Form.Item
        name={['pharmacy', 'phone_number']}
        label={t('Phone number(Required for Login)')}
        messageVariables={{labelAlias: t('Phone number')}}
        rules={phoneNumberRules({isRequired, isLabelAliased: true})}
      >
        <Input
          maxLength={inputMaxLength.phoneNumber}
          placeholder={
            isDisabled ? '' : t('Placeholders.Pharmacy phone number')
          }
          disabled={isDisabled}
        />
      </Form.Item>
    ),
    zipCode: (
      <Form.Item
        name={['pharmacy', 'zip_code']}
        label={t('Postal code')}
        rules={zipCodeRules({isRequired})}
      >
        {isDisabled ? (
          <Input
            maxLength={inputMaxLength.zipCode}
            placeholder={isDisabled ? '' : t('Placeholders.Postal code')}
            disabled={isDisabled}
          />
        ) : (
          <Input.Search
            maxLength={inputMaxLength.zipCode}
            placeholder={isDisabled ? '' : t('Placeholders.Postal code')}
            loading={isLoading}
            onSearch={onSearchAddress}
            disabled={isDisabled}
          />
        )}
      </Form.Item>
    ),
    location: (
      <Form.Item
        name={['pharmacy', 'location']}
        label={t('Pharmacy location')}
        rules={requiredOnly({isRequired})}
      >
        <Input
          maxLength={inputMaxLength.location}
          placeholder={isDisabled ? '' : t('Placeholders.Pharmacy location')}
          disabled={isDisabled}
        />
      </Form.Item>
    ),
    street: (
      <Form.Item
        name={['pharmacy', 'street']}
        label={t('Street number and building name')}
        rules={requiredOnly({isRequired})}
      >
        <Input
          maxLength={inputMaxLength.street}
          placeholder={isDisabled ? '' : t('Placeholders.Pharmacy street')}
          disabled={isDisabled}
        />
      </Form.Item>
    ),
  };

  return (
    <Card
      title={t('Pharmacy information')}
      bordered
      className="form-card"
      size="small"
      extra={extra}
    >
      <Row gutter={24} align={'bottom'}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {FormItems.name}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {FormItems.facilityCode}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {FormItems.phoneNumber}
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          {FormItems.zipCode}
        </Col>
        <Col xs={24} sm={24} md={24} lg={9} xl={9}>
          {FormItems.location}
        </Col>
        <Col xs={24} sm={24} md={24} lg={9} xl={9}>
          {FormItems.street}
        </Col>
      </Row>
    </Card>
  );
};

export default PharmacyInfoCard;
