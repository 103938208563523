import {FilePdfOutlined} from '@ant-design/icons';
import {Button, Card, Space, Typography, Row, Col} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Loading from '@app/components/Loading/Loading';
import {useGetManualsQuery} from '@app/redux/services/settingService';
import {EManualType} from '@app/constants';

const {Text} = Typography;

const ManualInfo: FC = () => {
  const {t} = useTranslation();
  const {isLoading, data: manualUrlData} = useGetManualsQuery();

  const openPdfInNewTab = (pdfUrl: string | undefined) => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
    }
  };

  const renderManualContent = (
    type: EManualType,
    pdfUrl: string | undefined,
  ) => {
    return (
      <Space
        direction="vertical"
        size={24}
        style={{width: '100%', marginBottom: '16px'}}
      >
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{textAlign: 'left'}}
          >
            <Text>{t(`Manuals for ${type}`)}</Text>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{textAlign: 'right'}}
          >
            <Button
              type="primary"
              icon={<FilePdfOutlined />}
              onClick={() => openPdfInNewTab(pdfUrl)}
            >
              {t('Jump To Manual')}
            </Button>
          </Col>
        </Row>
      </Space>
    );
  };

  return (
    <>
      {isLoading && <Loading />}
      <div>
        <Card title={t('Manual Info')} bordered={false}>
          <div>
            {manualUrlData && (
              <>
                {renderManualContent(
                  EManualType.app,
                  manualUrlData.app_manual_url,
                )}
                {renderManualContent(
                  EManualType.web,
                  manualUrlData.web_manual_url,
                )}
              </>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default ManualInfo;
