import {Form} from 'antd';
import {useRegisterMutation} from '@app/redux/services/userService';
import {useMemo} from 'react';
import {TError, TRegisterRequest} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Routes} from '@app/router/Routes';
import {ErrorCodes} from '@app/constants';

export const useRegistrationForm = () => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [register, {isLoading}] = useRegisterMutation();
  const navigate = useNavigate();

  const onSubmit = async () => {
    const values: TRegisterRequest = await form.validateFields();
    try {
      await register(values).unwrap();
      navigate(Routes.RegistrationFinished);
    } catch (error) {
      const {data: errorData} = error as TError;
      const errorMessage = [
        ErrorCodes.phoneNumberAlreadyRegisteredError,
        ErrorCodes.emailAlreadyRegisteredError,
        ErrorCodes.facilityCodeAlreadyRegisteredError,
      ].includes(errorData?.error_code)
        ? errorData.message
        : t('An error occurred during the registration!');
      showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
    }
  };

  const initialValues: TRegisterRequest = useMemo(() => {
    return {
      full_name: '',
      full_name_kana: '',
      email: '',
      pharmacy: {
        name: '',
        facility_code: '',
        phone_number: '',
        zip_code: '',
        location: '',
        street: '',
      },
      company: {
        name: '',
        zip_code: '',
        location: '',
        street: '',
      },
    };
  }, []);

  return {
    form,
    initialValues,
    isLoading,
    onSubmit,
  };
};
