import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Input, Radio, Button, Row, Col, Checkbox, Space} from 'antd';
import Loading from '@app/components/Loading/Loading';
import {useIsDemo} from '@app/hooks/useIsDemo';
import {usePaymentInvoiceForm} from '@app/pages/PaymentInfo/usePaymentInvoiceForm';
import {findAddress} from '@app/utils/geocodeApi';
import {Routes} from '@app/router/Routes';
import {useNavigate} from 'react-router-dom';
import {EInvoiceDeliveryMethod, EPaymentInterval} from '@app/constants';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';

const PaymentInvoiceForm: React.FC = () => {
  const {t} = useTranslation();
  const isDemo = useIsDemo();
  const navigate = useNavigate();

  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const {
    form,
    isLoading,
    onSubmit,
    selectedMethods,
    onSelectMethod,
    isEditing,
    setIsEditing,
  } = usePaymentInvoiceForm();
  const isDisabled = !isEditing && !isDemo;
  const inputMaxLength = useInputMaxLength();

  const {
    requiredOnly,
    katakanaRules,
    emailRules,
    phoneNumberRules,
    zipCodeRules,
    websiteRules,
  } = useFormValidationRules();

  const onSearchAddress = async () => {
    setIsLocationLoading(true);
    const zipCode = form.getFieldValue('zip_code');
    const location = await findAddress(zipCode);
    form.setFieldsValue({location: location});
    setIsLocationLoading(false);
  };

  const onSave = () => {
    onSubmit();
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onUnsubscribe = () => {
    navigate(Routes.Unsubscribe);
  };

  const onCancel = () => {
    setIsEditing(false);
  };

  const buttons = (
    <Space>
      {isDemo ? (
        <Button key="buy" type="primary" htmlType="submit">
          {t('Purchase')}
        </Button>
      ) : isEditing ? (
        <>
          <Button key="save" type="primary" htmlType="submit">
            {t('Save')}
          </Button>
          <Button type="default" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </>
      ) : (
        <>
          <Button type="primary" onClick={onEdit}>
            {t('Edit')}
          </Button>
          <Button danger onClick={onUnsubscribe}>
            {t('Cancellation request')}
          </Button>
        </>
      )}
    </Space>
  );

  return (
    <div>
      {isLoading && <Loading />}
      <div>
        <Form
          id="payment-invoice-form"
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onSave}
        >
          <Row gutter={24} align={'bottom'}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label={t('Billing full name')}
                name="billing_name"
                rules={requiredOnly({isRequired: isDemo})}
              >
                <Input
                  maxLength={inputMaxLength.fullName}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.Full name')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="billing_name_kana"
                label={t('Billing full name kana')}
                rules={katakanaRules({isRequired: isDemo})}
              >
                <Input
                  maxLength={inputMaxLength.fullNameKana}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.Full name in Katakana')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="company_name"
                label={t('Company name')}
                rules={requiredOnly({isRequired: isDemo})}
              >
                <Input
                  maxLength={inputMaxLength.companyName}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.Company name')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item name="department" label={t('Department')}>
                <Input
                  maxLength={inputMaxLength.department}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.Department')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item name="position" label={t('Job title')}>
                <Input
                  maxLength={inputMaxLength.position}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.Job title')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="website"
                label={t('Home page')}
                rules={websiteRules({isRequired: false})}
              >
                <Input
                  maxLength={inputMaxLength.website}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.Home page')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="email"
                label={t('Billing email')}
                rules={emailRules({isRequired: isDemo})}
              >
                <Input
                  maxLength={inputMaxLength.email}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.User email')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="phone_number"
                label={t('Phone number')}
                rules={phoneNumberRules({isRequired: isDemo})}
              >
                <Input
                  maxLength={inputMaxLength.phoneNumber}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.Company phone number')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                name="representative_name"
                label={t('Representative full name')}
                rules={requiredOnly({isRequired: isDemo})}
              >
                <Input
                  maxLength={inputMaxLength.fullName}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.Full name')}
                />
              </Form.Item>
            </Col>
            <Col xs={16} sm={16} md={16} lg={4} xl={4}>
              <Form.Item
                name="zip_code"
                label={t('Postal code')}
                rules={zipCodeRules({isRequired: isDemo})}
              >
                <Input.Search
                  maxLength={inputMaxLength.zipCode}
                  loading={isLocationLoading}
                  onSearch={onSearchAddress}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.Postal code')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <Form.Item
                name="location"
                label={t('Address')}
                rules={requiredOnly({isRequired: isDemo})}
              >
                <Input
                  maxLength={inputMaxLength.location}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.Company location')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <Form.Item
                name="street"
                label={t('Street number and building name')}
                rules={requiredOnly({isRequired: isDemo})}
              >
                <Input
                  maxLength={inputMaxLength.street}
                  disabled={isDisabled}
                  placeholder={t('Placeholders.Company street')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Form.Item
                name="interval"
                label={t('Payment Count')}
                required={isDemo}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button
                    value={EPaymentInterval.monthly}
                    disabled={!isDemo}
                  >
                    {t('Pay MONTHLY')}
                  </Radio.Button>
                  <Radio.Button
                    value={EPaymentInterval.annual}
                    disabled={!isDemo}
                  >
                    {t('Pay ANNUAL')}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
              <Form.Item label={t('Invoice type')} required={isDemo}>
                <Checkbox
                  disabled={isDisabled}
                  checked={selectedMethods.includes(
                    EInvoiceDeliveryMethod.email,
                  )}
                  onChange={() => onSelectMethod(EInvoiceDeliveryMethod.email)}
                >
                  {t('PDF(Recommended)')}
                </Checkbox>
                <Checkbox
                  disabled={isDisabled}
                  checked={selectedMethods.includes(
                    EInvoiceDeliveryMethod.posting,
                  )}
                  onChange={() =>
                    onSelectMethod(EInvoiceDeliveryMethod.posting)
                  }
                >
                  {t('Postcard(costs 209 yen per card)')}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {buttons}
        </Form>
      </div>
    </div>
  );
};

export default PaymentInvoiceForm;
