import Loading from '@app/components/Loading/Loading';
import {InboxOutlined, SendOutlined} from '@ant-design/icons';
import {useInquiryForm} from '@app/pages/Inquiry/useInquiryForm';
import {Button, Card, Form, Input, Upload} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import type {UploadProps} from 'antd';
import {useFormValidationRules} from '@app/utils/formValidationRules';
import {UploadChangeParam} from 'antd/lib/upload';

const {Dragger} = Upload;

const props: UploadProps = {
  name: 'file',
  multiple: true,
  customRequest: ({onSuccess}) => {
    onSuccess && onSuccess('ok');
  },
  maxCount: 5,
};

const FormInquiry: FC = () => {
  const {t} = useTranslation();
  const {form, onSendInquiry, isLoading} = useInquiryForm();
  const {requiredOnly} = useFormValidationRules();

  const normFile = (e: UploadChangeParam) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Card title={t('Inquiry form')}>
      <Form
        layout="vertical"
        form={form}
        labelAlign="left"
        onFinish={onSendInquiry}
      >
        {isLoading && <Loading />}
        <Form.Item
          label={t('Subject')}
          name="title"
          required
          rules={requiredOnly()}
        >
          <Input style={{display: 'block'}} />
        </Form.Item>
        <Form.Item
          label={t('Inquiry content')}
          name="text"
          required
          rules={requiredOnly()}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item
          label={t('Attachment')}
          name="attachments"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {t('Please click or drag and drop files to upload in this area.')}
            </p>
            <p className="ant-upload-hint">
              {t('We support single or bulk uploads.')}
            </p>
          </Dragger>
        </Form.Item>
        <Form.Item>
          <Button
            icon={<SendOutlined className="ant-icon" />}
            type="primary"
            htmlType="submit"
            className="ant-btn-primary ant-btn-lg"
          >
            {t('Send')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default FormInquiry;
