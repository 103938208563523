import {
  useUpdatePharmacyMutation,
  useCreatePharmacyMutation,
  useLazyGetPharmacyByIdQuery,
} from '@app/redux/services/pharmacyService';
import {TError, TPharmacyCreate, TPharmacyUpdate} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {Form} from 'antd';
import {t} from 'i18next';
import {useEffect} from 'react';
import {ErrorCodes} from '@app/constants';
import useGeolocation from '@app/hooks/useGeolocation';
import {useInvitePharmacyMutation} from '@app/redux/services/userService';

interface IUsePharmacyForm {
  pharmacyId?: number;
  onCancel: () => void;
}

export const usePharmacyForm = ({pharmacyId, onCancel}: IUsePharmacyForm) => {
  const [form] = Form.useForm<TPharmacyCreate | TPharmacyUpdate>();
  const [createPharmacy, {isLoading: isCreatingPharmacy}] =
    useCreatePharmacyMutation();
  const [updatePharmacy, {isLoading: isUpdatingPharmacy}] =
    useUpdatePharmacyMutation();
  const [invitePharmacy, {isLoading: isInvitingPharmacy}] =
    useInvitePharmacyMutation();
  const [getPharmacyById] = useLazyGetPharmacyByIdQuery();

  const {isLoading: isLocationLoading, onSearch} = useGeolocation();

  useEffect(() => {
    const setInitialValues = async () => {
      if (!pharmacyId) return;
      const pharmacy = await getPharmacyById(pharmacyId).unwrap();
      form.setFieldsValue(pharmacy);
    };
    setInitialValues();

    return () => {
      if (!pharmacyId) return;
      form.resetFields();
    };
  }, [pharmacyId]);

  const onSearchAddress = async () => {
    const zipCode = form.getFieldValue('zip_code');
    const address = await onSearch(zipCode);
    form.setFieldsValue({location: address});
  };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (pharmacyId) {
        await updatePharmacy(values as TPharmacyUpdate).unwrap();
      } else {
        await createPharmacy(values as TPharmacyCreate).unwrap();
      }
      const successMessage = pharmacyId
        ? t('Successfully updated!')
        : t('Successfully registered!');
      showNotification(t('Success'), successMessage, 'bottomRight', 'success');
      onCancel();
      form.resetFields();
    } catch (error) {
      const {data: errorData} = error as TError;
      const errorMessage = [
        ErrorCodes.phoneNumberAlreadyRegisteredError,
        ErrorCodes.emailAlreadyRegisteredError,
        ErrorCodes.facilityCodeAlreadyRegisteredError,
        ErrorCodes.limitedDueToDemoModeError,
      ].includes(errorData?.error_code)
        ? errorData.message
        : pharmacyId
          ? t('An error occurred during the update!')
          : t('An error occurred during the registration!');
      showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
    }
  };

  const onInvite = async () => {
    try {
      const values = (await form.validateFields([
        'manager',
        'email',
      ])) as TPharmacyCreate;
      await invitePharmacy({email: values.manager.email}).unwrap();
      showNotification(
        t('Registration request email has been sent.'),
        t(
          'As a pharmacy administrator, you will need to register pharmacy information and configure integration with the receipt computer',
        ),
        'bottomRight',
        'success',
      );
      onCancel();
      form.resetFields();
    } catch (error) {
      const {data: errorData} = error as TError;
      const errorMessage = [
        ErrorCodes.emailAlreadyRegisteredError,
        ErrorCodes.limitedDueToDemoModeError,
      ].includes(errorData?.error_code)
        ? errorData.message
        : t('An error occurred while sending the registration request email.');
      showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
    }
  };

  return {
    form,
    isLocationLoading,
    onSearchAddress,
    isLoading: isCreatingPharmacy || isUpdatingPharmacy || isInvitingPharmacy,
    onSubmit,
    onInvite,
  };
};
