import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Routes} from '@app/router/Routes';
import {useUserRole} from '@app/hooks/useUserRole';
import {
  HistoryOutlined,
  MedicineBoxOutlined,
  PayCircleOutlined,
  MoneyCollectOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  SnippetsOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {routerConfig} from '@app/router/routeConfig';

const useMenuItems = () => {
  const {t} = useTranslation();
  const userRole = useUserRole();
  // const isOwner = userRole === EUserRole.owner;
  // const isCompanyManager = userRole === EUserRole.companyManager;
  // const isPharmacyManager = userRole === EUserRole.pharmacyManager;

  const hasAccessToRoute = (route: string) => {
    const routeConfigPath = routerConfig.find((item) => item.path === route);

    if (routeConfigPath && userRole) {
      return (
        routeConfigPath.accessableRoles?.includes(userRole) ||
        !routeConfigPath.accessableRoles
      );
    }

    return false;
  };

  const menuItems = [
    {
      icon: <HistoryOutlined />,
      key: Routes.AuditHistory,
      label: t('Audit History'),
      path: Routes.AuditHistory,
      visible: hasAccessToRoute(Routes.AuditHistory),
    },
    {
      icon: <MedicineBoxOutlined />,
      key: Routes.PharmacyList,
      label: t('Pharmacy List'),
      path: Routes.PharmacyList,
      visible: hasAccessToRoute(Routes.PharmacyList),
    },
    {
      icon: <PayCircleOutlined />,
      key: Routes.PaymentInfo,
      label: t('Payment Info'),
      path: Routes.PaymentInfo,
      visible: hasAccessToRoute(Routes.PaymentInfo),
    },
    {
      icon: <MoneyCollectOutlined />,
      key: Routes.PaymentHistory,
      label: t('Payment History'),
      path: Routes.PaymentHistory,
      visible: hasAccessToRoute(Routes.PaymentHistory),
    },
    {
      icon: <SettingOutlined />,
      key: Routes.ReseconList,
      label: t('Receipt Computer Settings'),
      path: Routes.ReseconList,
      visible: hasAccessToRoute(Routes.ReseconList),
    },
    {
      icon: <UsergroupAddOutlined />,
      key: Routes.UserManagement,
      label: t('User Management'),
      path: Routes.UserManagement,
      visible: hasAccessToRoute(Routes.UserManagement),
    },
    {
      icon: <UserOutlined />,
      key: Routes.AccountSettings,
      label: t('Registration Info'),
      path: Routes.AccountSettings,
      visible: hasAccessToRoute(Routes.AccountSettings),
    },
    {
      icon: <SnippetsOutlined />,
      key: Routes.ManualInfo,
      label: t('Manual Info'),
      path: Routes.ManualInfo,
      visible: hasAccessToRoute(Routes.ManualInfo),
    },
    {
      icon: <SendOutlined />,
      key: Routes.FormInquiry,
      label: t('Form Inquiry'),
      path: Routes.FormInquiry,
      visible: hasAccessToRoute(Routes.FormInquiry),
    },
  ];
  const {pathname} = useLocation();
  const selectedMenuItem = menuItems.find((item) => item.path === pathname);

  return {
    menuItems: menuItems
      .filter((item) => item.visible)
      .map((item) => ({
        icon: item.icon,
        key: item.key,
        label: (
          <>
            {item.label}
            <Link to={{pathname: item.path}} />
          </>
        ),
      })),
    selectedMenuItem,
  };
};

export default useMenuItems;
