import {useAppDispatch, useAppSelector} from '@app/redux/reduxHooks';
import {useLazyGetMeQuery} from '@app/redux/services/userService';
import {clearUser, login, updateUserInfo} from '@app/redux/slices/userSlice';
import {TLogin} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {t} from 'i18next';

const useLoginForm = () => {
  const dispatch = useAppDispatch();
  const {isFetching} = useAppSelector((state) => state.user);
  const [getMe, result] = useLazyGetMeQuery();

  const onFinish = async (values: TLogin) => {
    try {
      dispatch(clearUser());
      await dispatch(login(values)).unwrap();
      const user = await getMe().unwrap();
      if (user) {
        dispatch(updateUserInfo(user));
      } else {
        dispatch(clearUser());
      }
    } catch (rejectedValueOrSerializedError) {
      showNotification(
        t('Error'),
        t('There is an error in the input. Please check and enter it again.'),
        'bottomRight',
        'error',
      );
    }
  };

  return {
    isLoading: isFetching || result.isLoading,
    onFinish,
  };
};

export default useLoginForm;
