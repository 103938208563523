import Loading from '@app/components/Loading/Loading';
import {Routes} from '@app/router/Routes';
import {Button, Form, Input} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import useForgotPasswordConfirmForm from './useForfotPasswordConfirmForm';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';
import {CONSTANTS} from '@app/constants';
import AuthLogo from '@app/components/Logo/AuthLogo';

const ForgotPasswordConfirm: FC = () => {
  const {t} = useTranslation();
  const {form, initialValues, onFinish, isLoading} =
    useForgotPasswordConfirmForm();
  const {
    emailRules,
    confirmationCodeRules,
    passwordRules,
    passwordConfirmationRules,
  } = useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  const content = (
    <Form
      form={form}
      initialValues={initialValues}
      autoComplete="off"
      onFinish={onFinish}
      labelCol={{span: 0}}
      wrapperCol={{span: 24}}
    >
      <Form.Item name="email" label={t('Email address')} rules={emailRules()}>
        <Input
          maxLength={inputMaxLength.email}
          placeholder={t('Email address')}
          disabled
        />
      </Form.Item>
      <Form.Item
        name="confirmation_code"
        label={t('Confirmation code')}
        messageVariables={{labelAlias: t('Confirmation code')}}
        rules={confirmationCodeRules({isLabelAliased: true})}
      >
        <Input
          maxLength={inputMaxLength.confirmationCode}
          placeholder={t('Confirmation code')}
        />
      </Form.Item>
      <Form.Item
        name={CONSTANTS.PASSWORD_FORM_FIELD_NAME}
        label={t('Password')}
        messageVariables={{labelAlias: t('Password')}}
        rules={passwordRules({isLabelAliased: true})}
      >
        <Input.Password
          maxLength={inputMaxLength.password}
          placeholder={t('Password')}
        />
      </Form.Item>
      <Form.Item
        name={CONSTANTS.PASSWORD_CONFIRMATION_FORM_FIELD_NAME}
        label={t('Password confirmation')}
        messageVariables={{labelMatch: t('Password')}}
        rules={passwordConfirmationRules({
          isRequired: true,
        })}
      >
        <Input.Password
          maxLength={inputMaxLength.password}
          placeholder={t('Password confirmation')}
        />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        {t('Reset password')}
      </Button>
      <Button type="link">
        <Link to={Routes.Login}>{t('Return to the login screen')}</Link>
      </Button>
    </Form>
  );

  return (
    <>
      {isLoading && <Loading />}
      <AuthLogo title={t('Login page')} content={content} />
    </>
  );
};

export default ForgotPasswordConfirm;
