import {SmileOutlined} from '@ant-design/icons';
import {CONSTANTS} from '@app/constants';
import {Button, Result} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Routes} from '@app/router/Routes';
import {useSearchParams} from 'react-router-dom';
import {useState, useEffect} from 'react';
import AuthLogo from '@app/components/Logo/AuthLogo';

interface EventData {
  event_name: string;
  event_date: string;
  title: string;
  body: string;
}

const EventPublicRelations: FC<unknown> = () => {
  const {t} = useTranslation();
  const [searchParams] = useSearchParams();
  const eventInfoPath =
    CONSTANTS.EVENT_PUBLIC_RELATION_STATIC +
    '/events/' +
    searchParams.get('id') +
    '/info.json';

  const [eventData, setEventData] = useState<EventData>({
    event_name: '',
    event_date: '',
    title: '',
    body: '',
  });

  const sampleEventData = {
    event_name: '',
    event_date: '',
    title: t('Thank you'),
    body: t('Thank you for your interest in OKUSURI-AI.'),
  };

  useEffect(() => {
    fetch(eventInfoPath)
      .then((response) => response.json())
      .then((data) => {
        setEventData(data);
      })
      .catch((error) => {
        console.error('Error fetching event data:', error);
        setEventData(sampleEventData);
      });
  }, [eventInfoPath]);

  return (
    <>
      <AuthLogo
        title={eventData.event_name}
        content={
          <Result
            icon={<SmileOutlined color="#0665ac" height="20vh" />}
            title={eventData.title}
            subTitle={eventData.body}
            extra={[
              <Button type="primary" key="registration">
                <Link to={Routes.Registration}>{t('entry')}</Link>
              </Button>,
            ]}
          />
        }
      />
    </>
  );
};

export default EventPublicRelations;
