import {useDownloadPaymentMutation} from '@app/redux/services/paymentService';
import {TPayment} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {TableProps} from 'antd';
import {t} from 'i18next';
import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';

export const usePaymentList = () => {
  const [selectedPayment, setSelectedPayment] = useState<
    TPayment | undefined
  >();
  const [searchParams, setSearchParams] = useSearchParams();
  const [downloadPayment] = useDownloadPaymentMutation();

  const onTableChange: TableProps<TPayment>['onChange'] = (pagination) => {
    const {current, pageSize} = pagination;
    setSearchParams({
      current: String(current),
      pageSize: String(pageSize),
    });
  };

  const download = async (id: number) => {
    try {
      await downloadPayment({id: id}).unwrap();
      showNotification(
        t('payment.download.success.title'),
        t('payment.download.success.message'),
        'bottomRight',
        'success',
      );
    } catch (error) {
      showNotification(
        t('payment.download.error.title'),
        t('payment.download.error.title'),
        'bottomRight',
        'error',
      );
    }
  };

  return {
    selectedPayment,
    setSelectedPayment,
    searchParams,
    setSearchParams,
    onTableChange,
    download,
  };
};
