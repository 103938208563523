import {notification} from 'antd';
import {NotificationPlacement} from 'antd/es/notification/interface';

const showNotification = (
  title: string,
  message: string,
  placement: NotificationPlacement,
  type: 'error' | 'info' | 'success' | 'warning',
): void => {
  if (type === 'error') {
    notification.error({
      message: title,
      description: message,
      placement,
    });
  }
  if (type === 'info') {
    notification.info({
      message: title,
      description: message,
      placement,
    });
  }

  if (type === 'success') {
    notification.success({
      message: title,
      description: message,
      placement,
    });
  }
  if (type === 'warning') {
    notification.warning({
      message: title,
      description: message,
      placement,
    });
  }
};
export default showNotification;
