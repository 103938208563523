import {
  useUpdateMeEmailMutation,
  useConfirmMeEmailMutation,
} from '@app/redux/services/userService';
import {TError, TOwnEmailUpdate, TOwnEmailConfirm, TUser} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {useEffect} from 'react';
import {Form} from 'antd';
import {t} from 'i18next';
import {ErrorCodes} from '@app/constants';

interface IUsePharmacyForm {
  user: TUser | undefined;
  onSubmit: () => void;
  onCancel: () => void;
}

type TUseEmailChangeForm = {
  email: string;
  new_email: string;
  new_email_confirmation: string;
  password: string;
};

export const useEmailChangeForm = ({user, onSubmit}: IUsePharmacyForm) => {
  const [form] = Form.useForm<TUseEmailChangeForm | TOwnEmailConfirm>();

  const [updateOwnEmail, {isLoading: isUpdatingOwnEmail}] =
    useUpdateMeEmailMutation();

  const [confirmOwnEmail, {isLoading: isConfirmingOwnEmail}] =
    useConfirmMeEmailMutation();

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    }
  }, [user]);

  const onUpdate = async () => {
    const formValues = await form.validateFields();
    const {new_email, password} = formValues as TUseEmailChangeForm;
    if (user) {
      await updateOwnEmail({
        email: new_email,
        password: password,
      } as TOwnEmailUpdate)
        .unwrap()
        .then(() => {
          const successMessage = t('Successfully sent!');
          showNotification(
            t('Success'),
            successMessage,
            'bottomRight',
            'success',
          );
          onSubmit();
          form.resetFields();
          form.setFieldsValue(user);
        })
        .catch((error: TError) => {
          const {data: errorData} = error;
          const errorMessage = [
            ErrorCodes.emailAlreadyRegisteredError,
          ].includes(errorData?.error_code)
            ? errorData.message
            : errorData?.error_code ===
                ErrorCodes.incorrectInputInformationError
              ? t(
                  'There is an error in password. Please check and enter it again.',
                )
              : t('An error occurred during the sending!');
          showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
        });
    }
  };

  const onConfirm = async () => {
    const formValues = await form.validateFields();
    if (user) {
      await confirmOwnEmail(formValues as TOwnEmailConfirm)
        .unwrap()
        .then(() => {
          const successMessage = t('Successfully confirmed!');
          showNotification(
            t('Success'),
            successMessage,
            'bottomRight',
            'success',
          );
          onSubmit();
          form.resetFields();
        })
        .catch((error: TError) => {
          const {data: errorData} = error as TError;
          const errorMessage = [
            ErrorCodes.confirmationCodeExpiredError,
            ErrorCodes.invalidConfirmationCodeError,
          ].includes(errorData?.error_code)
            ? errorData.message
            : t('An error occurred during the confirmation!');
          showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
        });
    }
  };

  return {
    form,
    isLoading: isUpdatingOwnEmail || isConfirmingOwnEmail,
    onUpdate,
    onConfirm,
  };
};
