import {useUser} from '@app/hooks/useUser';
import {Routes} from '@app/router/Routes';
import {FC, PropsWithChildren, ReactNode} from 'react';
import {EUserRole} from '@app/constants';
import {Navigate} from 'react-router-dom';

interface IProtectedRouteProps {
  children: PropsWithChildren<ReactNode>;
  accessableRoles?: EUserRole[];
}

const ProtectedRoute: FC<IProtectedRouteProps> = ({
  children,
  accessableRoles,
}) => {
  const user = useUser();
  if (user?.id && (accessableRoles?.includes(user?.role) || !accessableRoles))
    return <>{children}</>;
  return <Navigate to={Routes.Login} />;
};

export default ProtectedRoute;
