import {Button, Form, Input, Modal} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {usePharmacyForm} from './usePharmacyForm';
import Loading from '@app/components/Loading/Loading';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';
interface IPharmacyDetailProps {
  pharmacyId?: number;
  open: boolean;
  onCancel: () => void;
}

const PharmacyDetail: FC<IPharmacyDetailProps> = ({
  pharmacyId,
  open,
  onCancel,
}) => {
  const {t} = useTranslation();
  const {form, isLocationLoading, onSearchAddress, isLoading, onSubmit} =
    usePharmacyForm({pharmacyId, onCancel});
  const isCreating = !pharmacyId;
  const {
    emailRules,
    requiredOnly,
    facilityCodeRules,
    phoneNumberRules,
    zipCodeRules,
  } = useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={isCreating ? t('Pharmacy registration') : t('Pharmacy info edit')}
      footer={null}
    >
      {isLoading && <Loading />}
      <Form
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
        labelCol={{span: 8, offset: 2}}
        wrapperCol={{span: 12}}
        labelAlign="left"
      >
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name={['manager', 'email']}
          label={t('Pharmacy admin email')}
          rules={emailRules({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.email}
            placeholder={t('Placeholders.Admin email')}
            disabled={!isCreating}
          />
        </Form.Item>
        <Form.Item
          name={['manager', 'full_name']}
          label={t('Pharmacy admin name')}
          rules={requiredOnly({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.fullName}
            placeholder={t('Placeholders.Full name')}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label={t('Pharmacy name')}
          rules={requiredOnly({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.pharmacyName}
            placeholder={t('Placeholders.Pharmacy name')}
          />
        </Form.Item>
        <Form.Item
          name="facility_code"
          label={t('Medical facility code')}
          rules={facilityCodeRules({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.facilityCode}
            placeholder={t('Placeholders.Medical facility code')}
          />
        </Form.Item>
        <Form.Item
          name="zip_code"
          label={t('Postal code')}
          rules={zipCodeRules({isRequired: isCreating})}
        >
          <Input.Search
            maxLength={inputMaxLength.zipCode}
            placeholder={t('Placeholders.Postal code')}
            loading={isLocationLoading}
            onSearch={onSearchAddress}
          />
        </Form.Item>
        <Form.Item
          name="location"
          label={t('Pharmacy location')}
          rules={requiredOnly({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.location}
            placeholder={t('Placeholders.Pharmacy location')}
          />
        </Form.Item>
        <Form.Item
          name="street"
          label={t('Street number and building name')}
          rules={requiredOnly({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.street}
            placeholder={t('Placeholders.Pharmacy street')}
          />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label={t('Pharmacy phone number')}
          rules={phoneNumberRules({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.phoneNumber}
            placeholder={t('Placeholders.Pharmacy phone number')}
          />
        </Form.Item>
        <div style={{textAlign: 'center'}}>
          <Button type="primary" htmlType="submit">
            {isCreating ? t('Register') : t('Save')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default PharmacyDetail;
