import {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Space, Form, Row, Col, Radio, Tooltip, Button} from 'antd';
import Loading from '@app/components/Loading/Loading';
import {useIsDemo} from '@app/hooks/useIsDemo';
import {useFormValidationRules} from '@app/utils/formValidationRules';
import {EPaymentInterval} from '@app/constants';
import {PayjpElement} from 'react-app-env';
import {
  useCreatePaymentCardMutation,
  useGetPaymentCardQuery,
  useUpdatePaymentCardMutation,
} from '@app/redux/services/paymentService';
import {
  useGetMeQuery,
  useLazyGetMeQuery,
} from '@app/redux/services/userService';
import {useAppDispatch} from '@app/redux/reduxHooks';
import {updateUserInfo} from '@app/redux/slices/userSlice';
import showNotification from '@app/utils/showNotification';
import {useNavigate} from 'react-router-dom';
import {Routes} from '@app/router/Routes';

const PaymentCardForm: FC = () => {
  const {t} = useTranslation();
  const isDemo = useIsDemo();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [form] = Form.useForm();
  const {data: card, isLoading} = useGetPaymentCardQuery();
  const {data: me} = useGetMeQuery();
  const dispatch = useAppDispatch();
  const [getUserMe] = useLazyGetMeQuery();

  const [createPaymentCard, {isLoading: isCreatingPaymentCard}] =
    useCreatePaymentCardMutation();
  const [updatePaymentCard, {isLoading: isUpdatingPaymentCard}] =
    useUpdatePaymentCardMutation();

  const {requiredOnly} = useFormValidationRules();
  const numberElement = useRef<PayjpElement>();
  const expiryElement = useRef<PayjpElement>();
  const cvcElement = useRef<PayjpElement>();
  const isDisabled = isDemo ? false : !isEditing;

  useEffect(() => {
    form.setFieldsValue({
      interval: me?.payment?.interval || EPaymentInterval.monthly,
    });
  }, [me]);

  useEffect(() => {
    const style = {
      base: {
        fontSize: '15px',
        ':disabled': {
          backgroundColor: '#f5f5f5',
        },
      },
    };
    const elements = window.payjp.elements();
    numberElement.current = elements.create('cardNumber', {
      style: style,
      placeholder: isDemo
        ? t('Placeholders.Card number')
        : `**** **** **** ${card ? card?.last4 : '****'}`,
      disabled: isDisabled,
    });
    expiryElement.current = elements.create('cardExpiry', {
      style: style,
      placeholder: isDemo
        ? t('Placeholders.Expiration date')
        : `${
            card
              ? card?.exp_month + '/' + card?.exp_year.toString().slice(-2)
              : '**/**'
          }`,
      disabled: isDisabled,
    });
    cvcElement.current = elements.create('cardCvc', {
      style: style,
      placeholder: isDemo ? t('Placeholders.Security code') : '***',
      disabled: isDisabled,
    });
    numberElement.current.mount('#number-form');
    expiryElement.current.mount('#expiry-form');
    cvcElement.current.mount('#cvc-form');
  }, [isDisabled, card]);

  const onSubmit = async () => {
    if (window.payjp && numberElement.current) {
      const result = await window.payjp.createToken(numberElement.current);

      if (result.error || result.card.cvc_check === 'failed') {
        const errorMessage = result.error
          ? result?.error.message || t('Payment Card Error Message')
          : t('Payment Card CVC Error Message');

        showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
        return;
      }

      const token = result.id;

      try {
        if (card) {
          await updatePaymentCard({token}).unwrap();
        } else {
          const {interval} = await form.validateFields();
          await createPaymentCard({token, interval}).unwrap();
          const user = await getUserMe().unwrap();
          if (user) {
            dispatch(updateUserInfo(user));
          }
        }
        const successMessage = card
          ? t('Successfully updated!')
          : t('Successfully registered!');

        showNotification(
          t('Success'),
          successMessage,
          'bottomRight',
          'success',
        );
        setIsEditing(false);
      } catch (error) {
        const errorMessage = card
          ? t('An error occurred during the update!')
          : t('An error occurred during the registration!');
        showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
      }
    }
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onUnsubscribe = () => {
    navigate(Routes.Unsubscribe);
  };

  const onCancel = () => {
    setIsEditing(false);
    form.resetFields();
  };

  const buttons = (
    <Space>
      {isDemo ? (
        <Button type="primary" htmlType="submit" onClick={onSubmit}>
          {t('Purchase')}
        </Button>
      ) : isEditing ? (
        <>
          <Button type="primary" htmlType="submit" onClick={onSubmit}>
            {t('Save')}
          </Button>
          <Button type="default" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </>
      ) : (
        <>
          <Button type="primary" onClick={onEdit}>
            {t('Edit')}
          </Button>
          <Button danger onClick={onUnsubscribe}>
            {t('Cancellation request')}
          </Button>
        </>
      )}
    </Space>
  );

  return (
    <div>
      {isLoading ||
        isCreatingPaymentCard ||
        (isUpdatingPaymentCard && <Loading />)}
      <Space direction="vertical" size={24} style={{width: '100%'}}>
        <Form form={form} layout="vertical" className="payjp-form">
          <Row gutter={16} align="bottom">
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Form.Item
                name="cardNumber"
                label={t('Card number')}
                className="payjp-item"
                required
              >
                <div
                  id="number-form"
                  className={`payjp-input${!isDisabled ? '' : '-disabled'}`}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Form.Item
                name="expirationDate"
                label={t('Expiration date')}
                required
              >
                <div
                  id="expiry-form"
                  className={`payjp-input${!isDisabled ? '' : '-disabled'}`}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Form.Item
                name="securityCode"
                label={t('Security code')}
                required
              >
                <Tooltip
                  title={t(
                    'CVC is the 3-digit number on the back of your card',
                  )}
                >
                  <div
                    id="cvc-form"
                    className={`payjp-input${!isDisabled ? '' : '-disabled'}`}
                  />
                </Tooltip>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Form.Item
                name="interval"
                label={t('Payment Count')}
                rules={requiredOnly({isRequired: isDemo})}
              >
                <Radio.Group buttonStyle="solid" disabled={!isDemo}>
                  <Radio.Button value={EPaymentInterval.monthly}>
                    {t('Pay MONTHLY')}
                  </Radio.Button>
                  <Radio.Button value={EPaymentInterval.annual}>
                    {t('Pay ANNUAL')}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {buttons}
        </Form>
      </Space>
    </div>
  );
};

export default PaymentCardForm;
