import {useUser} from '@app/hooks/useUser';
import {Routes} from '@app/router/Routes';
import {FC} from 'react';
import {Navigate} from 'react-router-dom';
import {useIsReseconRegistered} from '@app/hooks/useIsReseconRegistered';

import {EUserRole} from '@app/constants';

const HomeRedirect: FC = () => {
  const user = useUser();
  const isReseconRegistered = useIsReseconRegistered();

  const getRedirectPath = () => {
    if (user?.role === EUserRole.companyManager) {
      if (!user?.payment) return Routes.PaymentInfo;
      else return Routes.PharmacyList;
    } else if (user?.role === EUserRole.owner) {
      if (!user?.payment) return Routes.PaymentInfo;
      else if (!isReseconRegistered) return Routes.ReseconList;
      else return Routes.AuditHistory;
    } else if (user?.role === EUserRole.pharmacyManager) {
      if (!isReseconRegistered) return Routes.ReseconList;
      else return Routes.AuditHistory;
    } else {
      return Routes.AuditHistory;
    }
  };

  return <Navigate to={getRedirectPath()} />;
};

export default HomeRedirect;
