import {CONSTANTS} from '@app/constants';
import {TAuth, TUser, TLogin, TUserState, TLogout} from '@app/types';
import apiService from '@app/utils/apiConfig';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState: TUserState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const login = createAsyncThunk(
  'users/login',
  async (args: TLogin, {rejectWithValue}) => {
    try {
      const response = await apiService.post('login', args);
      const data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return rejectWithValue('username or password incorrect');
      }
    } catch (e: unknown) {
      return rejectWithValue('username or password incorrect');
    }
  },
);

export const logout = createAsyncThunk(
  'users/logout',
  async (args: TLogout) => {
    const response = await apiService.post('logout', args);
    const data = await response.data;
    if (response.status === 200) {
      return data;
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<TAuth>) {
      localStorage.setItem(CONSTANTS.TOKEN_KEY, JSON.stringify(action.payload));
      state.auth = action.payload;
    },
    updateUserInfo: (state, action: PayloadAction<TUser>) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      localStorage.removeItem(CONSTANTS.TOKEN_KEY);
      state.user = undefined;
      state.auth = undefined;
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.rejected, (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.message = 'error logging';
    });
    builder.addCase(login.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(login.fulfilled, (state, {payload}) => {
      if (payload && payload.message) {
        state.isFetching = false;
        state.isSuccess = true;
        state.message = 'error logging';
        return;
      }
      localStorage.setItem(CONSTANTS.TOKEN_KEY, JSON.stringify(payload));
      state.auth = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });
  },
});

export const userSelector = (state: {user: TUserState}): TUser | undefined =>
  state.user.user;
export const {setToken, clearUser, updateUserInfo} = userSlice.actions;
export default userSlice.reducer;
