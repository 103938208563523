import Loading from '@app/components/Loading/Loading';
import {
  IUseReseconForm,
  useReseconForm,
} from '@app/components/Modal/ReseconModal/useReseconForm';

import {Button, Form, Input, Modal, Popover, Space} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {CONSTANTS} from '@app/constants';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';

interface IReseconModalProps extends IUseReseconForm {
  open: boolean;
}

const ReseconModal: FC<IReseconModalProps> = ({reseconId, open, onCancel}) => {
  const {t} = useTranslation();
  const {form, onSubmit, isLoading} = useReseconForm({
    reseconId,
    onCancel,
  });
  const isCreating = !reseconId;
  const {requiredOnly, ipAddressRules} = useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={t('Receipt computer integration settings')}
      footer={null}
    >
      {isLoading && <Loading />}
      <Form
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
        labelCol={{span: 10, offset: 1}}
        wrapperCol={{span: 12}}
        labelAlign="left"
        requiredMark={isCreating}
      >
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="name"
          label={t('Receipt computer name')}
          rules={requiredOnly({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.receiptComputerName}
            placeholder={t('Placeholders.Receipt computer name')}
          />
        </Form.Item>
        <Form.Item
          name="ip_address"
          label={t('Ip address')}
          rules={ipAddressRules({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.ipAddress}
            placeholder={t('Placeholders.IP address')}
          />
        </Form.Item>
        <Form.Item
          name="username"
          label={t('Login name')}
          rules={requiredOnly({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.username}
            placeholder={t('Placeholders.Login name')}
          />
        </Form.Item>
        <Form.Item
          name={CONSTANTS.PASSWORD_FORM_FIELD_NAME}
          label={t('Password')}
          initialValue=""
        >
          <Input.Password
            maxLength={inputMaxLength.password}
            placeholder={t('Placeholders.Password')}
          />
        </Form.Item>
        <Form.Item
          name="share_name"
          messageVariables={{labelAlias: t('Share name')}}
          label={
            <span>
              <Space size={2}>
                {t('Share name')}
                <Popover
                  title={t('What is share name')}
                  content={<Trans i18nKey="Share name description" />}
                  placement="top"
                  overlayStyle={{maxWidth: '500px'}}
                >
                  <QuestionCircleOutlined />
                </Popover>
              </Space>
            </span>
          }
          rules={requiredOnly({isRequired: isCreating, isLabelAliased: true})}
        >
          <Input
            maxLength={inputMaxLength.shareName}
            placeholder={t('Placeholders.Share name')}
          />
        </Form.Item>
        <Form.Item
          name="share_folder"
          initialValue=""
          messageVariables={{labelAlias: t('Path')}}
          label={
            <span style={{whiteSpace: 'pre-wrap'}}>
              <Space size={2}>
                {t('Path')}
                <Popover
                  title={t('What is path')}
                  content={<Trans i18nKey="Path description" />}
                  placement="top"
                  overlayStyle={{maxWidth: '600px'}}
                >
                  <QuestionCircleOutlined />
                </Popover>
              </Space>
            </span>
          }
        >
          <Input
            maxLength={inputMaxLength.sharePathName}
            placeholder={t('Placeholders.Path')}
          />
        </Form.Item>
        <div style={{textAlign: 'center'}}>
          <Button type="primary" htmlType="submit">
            {t('Save')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ReseconModal;
