import {Alert} from 'antd';
import {FC} from 'react';
import {useIsDemo} from '@app/hooks/useIsDemo';
import {useTranslation} from 'react-i18next';

const MyBarner: FC = () => {
  const {t} = useTranslation();
  const isDemo = useIsDemo();

  return (
    <>
      {isDemo && (
        <Alert
          message={t(
            'The Limited Features And Functions As A Result Of The Demo Version',
          )}
          type="info"
          showIcon
          closable
          style={{marginBottom: 10}}
          banner
        />
      )}
    </>
  );
};

export default MyBarner;
