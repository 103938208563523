import useMenuItems from '@app/layout/sider/useMenuItems';
import {Menu} from 'antd';
import Sider from 'antd/lib/layout/Sider';
import {FC} from 'react';
import {useLocation} from 'react-router-dom';

const MySideBar: FC = () => {
  const {menuItems} = useMenuItems();
  const location = useLocation();

  return (
    <Sider breakpoint="lg" collapsedWidth="0" width={250}>
      <div style={{display: 'flex', height: '100%'}}>
        <Menu
          theme="light"
          mode="inline"
          items={menuItems}
          selectedKeys={[location.pathname]}
        />
      </div>
    </Sider>
  );
};

export default MySideBar;
