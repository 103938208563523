import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';
import {Card, Col, Form, Input, Row} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface IUserInfoCardProps {
  isDisabled?: boolean;
  isEmailDisabled?: boolean;
  isFieldsRequired?: boolean;
  extra?: JSX.Element;
}

const UserInfoCard: FC<IUserInfoCardProps> = ({
  isDisabled = false,
  isEmailDisabled = false,
  isFieldsRequired = true,
  extra,
}) => {
  const {t} = useTranslation();
  const isRequired = isDisabled ? false : isFieldsRequired;
  const isEmailRequired =
    isEmailDisabled || isDisabled ? false : isFieldsRequired;
  const {requiredOnly, katakanaRules, emailRules} = useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  const FormItems = {
    fullNameItem: (
      <Form.Item
        name="full_name"
        label={t('Full name')}
        rules={requiredOnly({isRequired})}
      >
        <Input
          maxLength={inputMaxLength.fullName}
          placeholder={isDisabled ? '' : t('Placeholders.Full name')}
          disabled={isDisabled}
        />
      </Form.Item>
    ),
    fullNameKanaItem: (
      <Form.Item
        name="full_name_kana"
        label={t('Full name in Katakana')}
        rules={katakanaRules({isRequired})}
      >
        <Input
          maxLength={inputMaxLength.fullNameKana}
          placeholder={
            isDisabled ? '' : t('Placeholders.Full name in Katakana')
          }
          disabled={isDisabled}
        />
      </Form.Item>
    ),
    email: (
      <Form.Item
        name="email"
        label={t('Email(Required for Login)')}
        messageVariables={{labelAlias: t('Email address')}}
        rules={emailRules({isRequired: isEmailRequired, isLabelAliased: true})}
      >
        <Input
          maxLength={inputMaxLength.email}
          placeholder={isDisabled ? '' : t('Placeholders.User email')}
          disabled={isEmailDisabled}
        />
      </Form.Item>
    ),
    department: (
      <Form.Item name="department" label={t('Department')}>
        <Input
          maxLength={inputMaxLength.department}
          placeholder={isDisabled ? '' : t('Placeholders.Department')}
          disabled={isDisabled}
        />
      </Form.Item>
    ),
    position: (
      <Form.Item name="position" label={t('Job title')}>
        <Input
          maxLength={inputMaxLength.position}
          placeholder={isDisabled ? '' : t('Placeholders.Job title')}
          disabled={isDisabled}
        />
      </Form.Item>
    ),
  };

  return (
    <Card
      title={t('General information')}
      bordered
      className="form-card"
      size="small"
      extra={extra}
    >
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {FormItems.fullNameItem}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {FormItems.fullNameKanaItem}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {FormItems.email}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {FormItems.department}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {FormItems.position}
        </Col>
      </Row>
    </Card>
  );
};

export default UserInfoCard;
