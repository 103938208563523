import baseQueryWithReauth from '@app/helper/baseQueryWithReAuth';
import {
  TPharmacy,
  TPharmacyCreate,
  TPharmacyList,
  TPaginationArgs,
  TPharmacyUpdate,
  TPharmacyUpdateOwn,
} from '@app/types';
import {createApi} from '@reduxjs/toolkit/query/react';

export const apiPharmacyService = createApi({
  reducerPath: 'pharmacyService',
  tagTypes: ['Pharmacy'],
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      createPharmacy: builder.mutation<TPharmacy, TPharmacyCreate>({
        query: (pharmacy) => ({
          url: '/pharmacies',
          method: 'POST',
          body: pharmacy,
        }),
        invalidatesTags: [{type: 'Pharmacy'}],
      }),
      getPharmacies: builder.query<TPharmacyList, TPaginationArgs>({
        query(args) {
          return {
            url: '/pharmacies',
            params: args,
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.data.map(({id}) => ({
                  type: 'Pharmacy' as const,
                  id,
                })),
                'Pharmacy',
              ]
            : ['Pharmacy'],
      }),
      getPharmacyById: builder.query<TPharmacy, number>({
        query: (pharmacyId) => `/pharmacies/${pharmacyId}`,
      }),
      updatePharmacy: builder.mutation<TPharmacy, TPharmacyUpdate>({
        query: (body) => ({
          url: `/pharmacies/${body.id}`,
          method: 'PATCH',
          body: body,
        }),
        invalidatesTags: (result, error, arg) => [
          {type: 'Pharmacy', id: arg.id},
        ],
      }),
      pharmacyResendEmail: builder.mutation<void, {id: number}>({
        query: ({id}) => ({
          url: `/pharmacies/${id}/resend_email`,
          method: 'POST',
        }),
      }),
      updateOwnPharmacy: builder.mutation<
        TPharmacyUpdateOwn,
        TPharmacyUpdateOwn
      >({
        query: (body) => ({
          url: `/pharmacies/own`,
          method: 'PATCH',
          body: body,
        }),
        invalidatesTags: ['Pharmacy'],
      }),
    };
  },
});

export const {
  useGetPharmaciesQuery,
  useGetPharmacyByIdQuery,
  useLazyGetPharmacyByIdQuery,
  useCreatePharmacyMutation,
  useUpdatePharmacyMutation,
  usePharmacyResendEmailMutation,
  useUpdateOwnPharmacyMutation,
} = apiPharmacyService;
