import {EUserRole} from '@app/constants';
import {useAppSelector} from '@app/redux/reduxHooks';
import {userSelector} from '@app/redux/slices/userSlice';

// Create a reusable function to check roles
const checkUserRole = (roleToCheck: EUserRole) => {
  const userRole = useAppSelector(userSelector)?.role;
  return userRole === roleToCheck;
};

export const useIsCompanyManager = () => {
  const isCompanyManagerRoles = [EUserRole.companyManager, EUserRole.owner];
  return isCompanyManagerRoles.some((role) => checkUserRole(role));
};

export const useIsPharmacyManager = () => {
  const isPharmacyManagerRoles = [EUserRole.pharmacyManager, EUserRole.owner];
  return isPharmacyManagerRoles.some((role) => checkUserRole(role));
};

export const useIsAdmin = () => {
  const isCompanyManager = useIsCompanyManager();
  const isPharmacyManager = useIsPharmacyManager();
  return isCompanyManager && isPharmacyManager;
};
