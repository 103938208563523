import Logo from '@app/components/Logo/Logo';
import {Col, Layout, Row, Typography} from 'antd';
import {FC} from 'react';

const {Header} = Layout;
const {Title} = Typography;

interface IMyBaseHeaderProps {
  title?: string;
}

const MyBaseHeader: FC<IMyBaseHeaderProps> = ({title}) => {
  return (
    <Header className="header" style={{width: '100%'}}>
      <Row gutter={24} align="middle" justify="space-between">
        <Col span={24} lg={8} className="header-logo">
          <Logo />
        </Col>
        <Col
          span={24}
          lg={8}
          style={{display: 'flex', justifyContent: 'center'}}
        >
          <Title level={5}>{title}</Title>
        </Col>
        <Col
          span={0}
          lg={8}
          style={{display: 'flex', justifyContent: 'center'}}
        ></Col>
      </Row>
    </Header>
  );
};

export default MyBaseHeader;
