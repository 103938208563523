import Loading from '@app/components/Loading/Loading';
import {useAuth} from '@app/hooks/useAuth';
import {useUser} from '@app/hooks/useUser';
import MyLayout from '@app/layout/MyLayout';
import Registration from '@app/pages/Registration/Registration';
import ForgotPassword from '@app/pages/ForgotPassword/ForgotPassword';
import ForgotPasswordConfirm from '@app/pages/ForgotPasswordConfirm/ForgotPasswordConfirm';
import SetPassword from '@app/pages/SetPassword/SetPassword';
import EventPublicRelations from '@app/pages/EventPublicRelations/EventPublicRelations';
import Login from '@app/pages/Login/Login';
import NotFound from '@app/pages/NotFound/NotFound';
import TermsOfUse from '@app/pages/TermsOfUse/TermsOfUse';
import HomeRedirect from '@app/router/HomeRedirect';
import CompletePharmacyInvitation from '@app/pages/CompletePharmacyInvitation/CompletePharmacyInvitation';
import ProtectedRoute from '@app/router/ProtectedRoute';
import {routerConfig} from '@app/router/routeConfig';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as ReactRouter,
} from 'react-router-dom';
import {Routes} from './Routes';
import RegistrationFinished from '@app/pages/Registration/RegistrationFinished';

const Router = (): JSX.Element => {
  const user = useUser();
  const {isLoading} = useAuth();

  if (isLoading) return <Loading />;

  return (
    <BrowserRouter>
      <ReactRouter>
        <Route path={'/'} element={<HomeRedirect />} />
        <Route
          path={Routes.Login}
          Component={() => {
            if (user?.id) return <Navigate to={'/app'} />;
            return <Login />;
          }}
        />
        <Route path={Routes.TermsOfService} element={<TermsOfUse />} />
        <Route path={Routes.Registration} element={<Registration />} />
        <Route
          path={Routes.RegistrationFinished}
          element={<RegistrationFinished />}
        />
        <Route
          path={Routes.ForgotPasswordConfirm}
          element={<ForgotPasswordConfirm />}
        />
        <Route path={Routes.SetPassword} element={<SetPassword />} />
        <Route path={Routes.ForgotPassword} element={<ForgotPassword />} />
        <Route
          path={Routes.CompletePharmacyInvitation}
          element={<CompletePharmacyInvitation />}
        />
        <Route
          path={Routes.EventPublicRelations}
          element={<EventPublicRelations />}
        />
        <Route path={'/app'} element={<HomeRedirect />} index />
        <Route path={'/app'} element={<MyLayout />}>
          {routerConfig.map(({path, Component, accessableRoles}) => {
            return (
              <Route
                path={`${path}`}
                element={
                  <ProtectedRoute accessableRoles={accessableRoles}>
                    <Component />
                  </ProtectedRoute>
                }
                key={path}
              />
            );
          })}
        </Route>
        <Route path="*" element={<NotFound />} />
      </ReactRouter>
    </BrowserRouter>
  );
};

export default Router;
