import {PlusOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import MyBarner from '@app/layout/barner/MyBarner';
import Loading from '@app/components/Loading/Loading';
import type {ColumnsType} from 'antd/es/table';
import ConfirmActionModal from '@app/components/Modal/ConfirmActionModal';
import StaffModal from '@app/components/Modal/StaffModal/StaffModal';
import {useStaffList} from '@app/pages/Staff/useStaffList';
import {useGetStaffsQuery} from '@app/redux/services/staffService';
import {Button, Col, Row, Space, Table, Typography} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {TStaff} from '@app/types';
import {useSearchParams} from 'react-router-dom';

const {Text} = Typography;

const StaffList: FC = () => {
  const {t} = useTranslation();
  const {isLoading, data: staffList} = useGetStaffsQuery();
  const [state, actions] = useStaffList();
  const [searchParams] = useSearchParams();

  const columns: ColumnsType<TStaff> = [
    {
      title: t('User ID'),
      dataIndex: 'username',
      key: 'username',
      width: '30%',
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('Full name'),
      dataIndex: 'full_name',
      key: 'full_name',
      sorter: true,
      width: '30%',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      width: '30%',
      render: (record: {id: number}) => {
        return (
          <Space>
            <Button
              onClick={() => {
                actions.onDetailModalOpen(record.id);
              }}
              icon={<EditOutlined />}
            >
              <Text className="btn-text">{t('Edit')}</Text>
            </Button>
            <Button
              onClick={() => {
                actions.onDeleteModalOpen(record.id);
              }}
              icon={<DeleteOutlined />}
              danger
            >
              <Text type="danger" className="btn-text">
                {t('Delete')}
              </Text>
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <MyBarner />
      <StaffModal
        open={state.isDetailModalOpen}
        onCancel={actions.onDetailModalClose}
        staffId={state.selectedStaffId}
      />
      {state.isDeleting && <Loading />}
      <Row gutter={[8, 8]} justify={'end'}>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => actions.onDetailModalOpen()}
          >
            {t('Add a user')}
          </Button>
        </Col>
        <Table
          style={{width: '100%'}}
          columns={columns}
          rowKey={(item) => item.id}
          loading={isLoading}
          dataSource={staffList?.data}
          pagination={{
            total: staffList?.meta.total_items,
            pageSize: staffList?.meta.items_per_page,
            current: Number(searchParams.get('page') ?? '1'),
            position: ['bottomRight'],
            hideOnSinglePage: false,
          }}
          onChange={actions.onTableChange}
          locale={{
            emptyText: (
              <div style={{textAlign: 'left'}}>
                {t('There are no registered users.')}
              </div>
            ),
          }}
        />
      </Row>
      <ConfirmActionModal
        open={state.isDeleteModalOpen}
        onConfirm={actions.handleDeleteStaff}
        onCancel={actions.onDeleteModalClose}
        text={t('Is it okay to delete this user information?')}
      />
    </>
  );
};

export default StaffList;
