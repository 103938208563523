import {FC} from 'react';
import signUpImage from '@app/assets/sign_up.png';
import {Button, Card, Col, Form, Image, Input, Row, Space} from 'antd';
import useCompletePharmacyInvitationForm from './useCompletePharmacyInvitationForm';
import {useTranslation} from 'react-i18next';
import Loading from '@app/components/Loading/Loading';
import UserInfoCard from '@app/components/FormCard/UserInfoCard';
import PharmacyInfoCard from '@app/components/FormCard/PharmacyInfoCard';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';
import {CONSTANTS} from '@app/constants';
import MyBaseHeader from '@app/layout/header/MyBaseHeader';

const CompletePharmacyInvitation: FC = () => {
  const {t} = useTranslation();
  const {form, initialValues, isLoading, onFinish} =
    useCompletePharmacyInvitationForm();
  const {passwordRules, passwordConfirmationRules} = useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  const passwordItems = {
    passwordItem: (
      <Form.Item
        name={CONSTANTS.PASSWORD_FORM_FIELD_NAME}
        label={t('Password')}
        rules={passwordRules()}
      >
        <Input.Password
          maxLength={inputMaxLength.password}
          placeholder={t('Placeholders.Password')}
        />
      </Form.Item>
    ),
    passwordConfirmItem: (
      <Form.Item
        name={CONSTANTS.PASSWORD_CONFIRMATION_FORM_FIELD_NAME}
        label={t('Password confirmation')}
        messageVariables={{labelMatch: t('Password')}}
        rules={passwordConfirmationRules({isRequired: true})}
      >
        <Input.Password
          maxLength={inputMaxLength.password}
          placeholder={t('Placeholders.Password')}
        />
      </Form.Item>
    ),
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyBaseHeader title={t('Pharmacy registration')} />
      <Row gutter={24}>
        <Col span={0} lg={6} offset={2}>
          <Image
            src={signUpImage}
            style={{
              height: 'auto',
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <Form
            layout="vertical"
            form={form}
            initialValues={initialValues}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Space direction="vertical">
              <UserInfoCard isEmailDisabled={true} />
              <PharmacyInfoCard form={form} />
              <Card title={t('Login password')} size="small">
                <Row gutter={24} align={'bottom'}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    {passwordItems.passwordItem}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    {passwordItems.passwordConfirmItem}
                  </Col>
                </Row>
              </Card>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ant-btn-lg ant-btn-block"
                >
                  {t('Register')}
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CompletePharmacyInvitation;
