type FormatDateArgs = {
  date?: string;
  format?: 'full' | 'dateOnly';
};

const padNumber = (num: number): string => num.toString().padStart(2, '0');

const formatDate = ({date, format = 'full'}: FormatDateArgs): string => {
  if (!date) return '';

  const parsedDate = new Date(date);

  const year = parsedDate.getFullYear();
  const month = padNumber(parsedDate.getMonth() + 1);
  const day = padNumber(parsedDate.getDate());

  if (format === 'dateOnly') {
    return `${year}年${month}月${day}日`;
  }

  const hour = padNumber(parsedDate.getHours());
  const minute = padNumber(parsedDate.getMinutes());

  return `${year}年${month}月${day}日 ${hour}時${minute}分`;
};

export default formatDate;
