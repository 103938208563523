import {CONSTANTS} from '@app/constants';
import {TAuth} from '@app/types';
export const getToken = (): TAuth | null => {
  try {
    const tokenObject = localStorage.getItem(CONSTANTS.TOKEN_KEY);
    if (typeof tokenObject !== 'undefined' && tokenObject)
      return JSON.parse(tokenObject);
    return null;
  } catch (e) {
    return null;
  }
};
