import {Button, Form, Input, Modal, Typography} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useEmailChangeForm} from './useEmailChangeForm';
import Loading from '@app/components/Loading/Loading';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';
import {useGetMeQuery} from '@app/redux/services/userService';

const {Text} = Typography;

interface ISendConfirmationModalProps {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const SendConfirmationModal: FC<ISendConfirmationModalProps> = ({
  open,
  onSubmit,
  onCancel,
}) => {
  const {t} = useTranslation();
  const {data: user} = useGetMeQuery();
  const {form, isLoading, onUpdate} = useEmailChangeForm({
    user,
    onSubmit,
    onCancel,
  });
  const {emailRules, emailConfirmationRules, requiredOnly} =
    useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  const onFinish = async () => {
    await onUpdate();
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={t('Own Email Edit')}
      footer={null}
    >
      {isLoading && <Loading />}
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{span: 10, offset: 1}}
        wrapperCol={{span: 12}}
        labelAlign="left"
      >
        <Form.Item name="email" label={t('Current Email')} rules={emailRules()}>
          <Input maxLength={inputMaxLength.email} disabled />
        </Form.Item>
        <Form.Item name="new_email" label={t('New Email')} rules={emailRules()}>
          <Input
            maxLength={inputMaxLength.email}
            placeholder={t('Placeholders.User email')}
          />
        </Form.Item>
        <Form.Item
          name="new_email_confirmation"
          label={t('New Email Confirmation')}
          rules={(emailRules(), emailConfirmationRules())}
        >
          <Input
            maxLength={inputMaxLength.email}
            placeholder={t('Placeholders.User email')}
          />
        </Form.Item>
        <Form.Item name="password" label={t('Password')} rules={requiredOnly()}>
          <Input.Password
            maxLength={inputMaxLength.password}
            placeholder={t('Placeholders.Password')}
          />
        </Form.Item>
        <div style={{textAlign: 'center'}}>
          <Text>
            {t('To Update Own Email, Will Send Confirmation Code to New Email')}
          </Text>
        </div>
        <div style={{textAlign: 'center', marginTop: '16px'}}>
          <Button type="primary" htmlType="submit">
            {t('Send Confirmation Code')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SendConfirmationModal;
