import MyHeader from '@app/layout/header/MyHeader';
import MySideBar from '@app/layout/sider/MySideBar';
import {Layout} from 'antd';
import {FC, PropsWithChildren} from 'react';
import {Outlet} from 'react-router-dom';
import useMenuItems from '@app/layout/sider/useMenuItems';

const {Content} = Layout;

interface IMyLayoutProps {
  title?: string;
}

const MyLayout: FC<PropsWithChildren<IMyLayoutProps>> = ({title}) => {
  const {selectedMenuItem} = useMenuItems();

  return (
    <Layout style={{width: '100vw', height: '100vh'}}>
      <MyHeader title={title || selectedMenuItem?.label} />
      <Layout hasSider>
        <MySideBar />
        <Content className="site-layout-background">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MyLayout;
