import {AppIcon} from '@app/assets/icons';
import {FC, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Routes} from '@app/router/Routes';

interface LogoProps {
  color?: string;
  isLarge?: boolean;
  style?: React.CSSProperties;
}

const sizeMapping: Record<string, number> = {
  small: 25,
  medium: 37,
  large: 74,
};

const Logo: FC<LogoProps> = ({color = '#0665ac', isLarge, style}) => {
  const [size, setSize] = useState<string>('medium');

  const aspectRatio = 792 / 167;
  const [height, setHeight] = useState(sizeMapping[size]);
  const width = height * aspectRatio;

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth <= 719) {
        setSize('small');
      } else {
        setSize('medium');
      }

      if (isLarge) {
        setSize('large');
      }

      setHeight(sizeMapping[size]);
    };

    handleResize(); // Call initially to set the size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [size]);

  return (
    <div className="logo">
      <Link to={Routes.Home}>
        <AppIcon color={color} height={height} width={width} style={style} />
      </Link>
    </div>
  );
};

export default Logo;
