import AuditDetail from '@app/components/Modal/AuditModal/AuditDetail';
import {useFetchAuditsQuery} from '@app/redux/services/auditService';
import {TAudit} from '@app/types';
import formatDate from '@app/utils/formatDate';
import {Col, DatePicker, Divider, Input, Row, Table, Tag} from 'antd';
import type {GetProps} from 'antd';
import dayjs from 'dayjs';
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import useAuditHistory from './useAuditHistory';

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const AuditHistory: FC = () => {
  const {RangePicker} = DatePicker;

  const dateFormat = 'YYYY年MM月DD日';

  const {t} = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchDates, setSearchDates] = useState<RangePickerProps['value']>(
    () => {
      const startDate = searchParams.get('created_at:gte');
      const endDate = searchParams.get('created_at:lte');

      if (startDate && endDate) {
        return [dayjs(startDate), dayjs(endDate)];
      } else if (startDate) {
        return [dayjs(startDate), null];
      } else if (endDate) {
        return [null, dayjs(endDate)];
      }

      return null;
    },
  );
  const [searchText, setSearchText] = useState<string | undefined>(
    searchParams.get('search_keyword:like')
      ? String(searchParams.get('search_keyword:like'))
      : undefined,
  );

  const [state, actions] = useAuditHistory();

  const resetPaginationParams = () => {
    searchParams.delete('page');
    searchParams.delete('limit');
  };

  const onSearch = () => {
    if (searchText) {
      searchParams.set('search_keyword:like', searchText);
    } else {
      searchParams.delete('search_keyword:like');
    }
    resetPaginationParams();
    setSearchParams(searchParams);
  };

  const {data: auditsList, isLoading} = useFetchAuditsQuery({
    limit: searchParams.get('limit')
      ? Number(searchParams.get('limit'))
      : undefined,
    page: searchParams.get('page')
      ? Number(searchParams.get('page'))
      : undefined,
    'search_keyword:like': searchParams.get('search_keyword:like')
      ? String(searchParams.get('search_keyword:like'))
      : undefined,
    'created_at:gte': searchParams.get('created_at:gte')
      ? String(searchParams.get('created_at:gte'))
      : undefined,
    'created_at:lte': searchParams.get('created_at:lte')
      ? String(searchParams.get('created_at:lte'))
      : undefined,
  });

  const columns = [
    {
      title: t('Patient name'),
      dataIndex: 'patient_name',
      key: 'patient_name',
      sorter: true,
      width: '25%',
      textWrap: 'word-break',
      render: (value: string) => <b style={{color: '#0665ac'}}>{value}</b>,
    },
    {
      title: t('Date'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      width: '15%',
      render: (value: string) => (
        <div>{formatDate({date: value, format: 'dateOnly'})}</div>
      ),
    },
    {
      title: t('Reception number'),
      dataIndex: 'reception_number',
      key: 'reception_number',
      sorter: true,
      width: '10%',
      render: (value: string) => <Tag className="blue-tag">{value}</Tag>,
    },
    {
      title: t('Auditor name'),
      dataIndex: 'auditor_name',
      key: 'auditor_name',
      sorter: true,
      width: '25%',
      textWrap: 'word-break',
    },
    {
      title: t('Medical facility name'),
      dataIndex: 'clinic_name',
      sorter: true,
      width: '25%',
      textWrap: 'word-break',
      render: (value: string, record: TAudit) => (
        <div>
          {value} {record.department_name}
        </div>
      ),
    },
  ];

  const onOpenChange = (open: boolean) => {
    if (!open) {
      const [startDate, endDate] = searchDates || [];

      if (startDate && endDate) {
        searchParams.set('created_at:gte', startDate.format('YYYY-MM-DD'));
        searchParams.set('created_at:lte', endDate.format('YYYY-MM-DD'));
      } else if (startDate) {
        searchParams.set('created_at:gte', startDate.format('YYYY-MM-DD'));
      } else if (endDate) {
        searchParams.set('created_at:lte', endDate.format('YYYY-MM-DD'));
      }
      resetPaginationParams();
      setSearchParams(searchParams);
    }
  };

  const onCalendarChange = (dates: RangePickerProps['value']) => {
    const newSearchParams = new URLSearchParams(searchParams);

    setSearchDates(dates);

    if (!dates) {
      newSearchParams.delete('created_at:gte');
      newSearchParams.delete('created_at:lte');
    } else {
      const [startDate, endDate] = dates;
      if (startDate) {
        newSearchParams.set('created_at:gte', startDate.format('YYYY-MM-DD'));
      } else {
        newSearchParams.delete('created_at:gte');
      }
      if (endDate) {
        newSearchParams.set('created_at:lte', endDate.format('YYYY-MM-DD'));
      } else {
        newSearchParams.delete('created_at:lte');
      }
    }

    setSearchParams(newSearchParams);
  };

  return (
    <>
      <AuditDetail
        auditId={state.selectedAuditId}
        open={state.isDetailModalOpen}
        onClose={actions.onDetailModalClose}
      />
      <Row gutter={16}>
        <Col span={6}>
          <Input.Search
            onSearch={onSearch}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearch();
              }
            }}
            value={searchText}
            placeholder={t('Search')}
            enterButton
          />
        </Col>
        <Col span={8}>
          <RangePicker
            value={searchDates}
            onOpenChange={onOpenChange}
            onCalendarChange={onCalendarChange}
            placeholder={[t('Start date'), t('End date')]}
            format={dateFormat}
          />
        </Col>
      </Row>
      <Divider />
      <Table
        columns={columns}
        dataSource={auditsList?.data}
        onRow={(row) => {
          return {
            onClick: () => {
              actions.onDetailModalOpen(row.id);
            },
          };
        }}
        pagination={{
          total: auditsList?.meta.total_items,
          pageSize: auditsList?.meta.items_per_page,
          current: Number(searchParams.get('page') ?? '1'),
          position: ['bottomRight'],
          hideOnSinglePage: false,
        }}
        sticky={false}
        loading={isLoading}
        rowKey={(item) => item.id}
        onChange={actions.onTableChange}
        locale={{
          emptyText: (
            <div style={{textAlign: 'left'}}>
              {t('There is currently no audit history available for display.')}
            </div>
          ),
        }}
      />
    </>
  );
};

export default AuditHistory;
