import {TAudit} from '@app/types';
import {TableProps} from 'antd';
import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';

interface IUseAuditHistoryState {
  selectedAuditId: number | undefined;
  isDetailModalOpen: boolean;
}

interface IUseAuditHistoryActions {
  onDetailModalOpen: (auditId: number) => void;
  onDetailModalClose: () => void;
  onTableChange: TableProps<TAudit>['onChange'];
}

const useAuditHistory = (): [
  IUseAuditHistoryState,
  IUseAuditHistoryActions,
] => {
  const [state, setState] = useState<IUseAuditHistoryState>({
    selectedAuditId: undefined,
    isDetailModalOpen: false,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const onTableChange: TableProps<TAudit>['onChange'] = (pagination) => {
    const {current, pageSize} = pagination;
    searchParams.set('page', String(current));
    searchParams.set('limit', String(pageSize));
    setSearchParams(searchParams);
  };

  const onDetailModalOpen = (auditId: number) => {
    setState((prevState) => ({
      ...prevState,
      selectedAuditId: auditId,
      isDetailModalOpen: true,
    }));
  };

  const onDetailModalClose = () => {
    setState((prevState) => ({
      ...prevState,
      selectedAuditId: undefined,
      isDetailModalOpen: false,
    }));
  };

  const actions: IUseAuditHistoryActions = {
    onDetailModalOpen,
    onDetailModalClose,
    onTableChange,
  };

  return [state, actions];
};

export default useAuditHistory;
