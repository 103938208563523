import {FC} from 'react';
import Logo from '@app/components/Logo/Logo';
import {Space, Typography} from 'antd';

const {Title} = Typography;

type AuthLogoProps = {
  title?: string;
  text?: string;
  content?: JSX.Element;
};

const AuthLogo: FC<AuthLogoProps> = ({title, text, content}) => {
  return (
    <div className="base-header">
      <Space direction="vertical" size="middle">
        <div className="title">
          <Title level={5}>{title}</Title>
        </div>
        <div className="header-content">
          <div>
            <Logo isLarge />
          </div>
          <div className="text">
            <Title level={5}>{text}</Title>
          </div>
        </div>
        <div>{content}</div>
      </Space>
    </div>
  );
};

export default AuthLogo;
