import {Button, Form, Input, Modal, Typography} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useEmailChangeForm} from './useEmailChangeForm';
import Loading from '@app/components/Loading/Loading';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';
import {useGetMeQuery} from '@app/redux/services/userService';

const {Text} = Typography;

interface IInputConfirmationModalProps {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const InputConfirmationModal: FC<IInputConfirmationModalProps> = ({
  open,
  onSubmit,
  onCancel,
}) => {
  const {t} = useTranslation();
  const {data: user} = useGetMeQuery();
  const {form, isLoading, onConfirm} = useEmailChangeForm({
    user,
    onSubmit,
    onCancel,
  });
  const {confirmationCodeRules} = useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={t('Own Email Edit')}
      footer={null}
    >
      {isLoading && <Loading />}
      <Form
        form={form}
        onFinish={onConfirm}
        autoComplete="off"
        labelCol={{span: 8, offset: 2}}
        wrapperCol={{span: 12}}
        labelAlign="left"
      >
        <Form.Item
          name="confirmation_code"
          label={t('Confirmation Code')}
          rules={confirmationCodeRules()}
        >
          <Input
            maxLength={inputMaxLength.confirmationCode}
            placeholder={t('Placeholders.Confirmation Code')}
          />
        </Form.Item>
        <div style={{textAlign: 'center'}}>
          <Text>{t('Confirmation Code Sent to Your Input New Email')}</Text>
          <Text>
            {t(
              'Please Check Your Confirmation Code and Input Confirmation Code',
            )}
          </Text>
        </div>
        <div style={{textAlign: 'center', marginTop: '16px'}}>
          <Button type="primary" htmlType="submit">
            {t('Edit')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default InputConfirmationModal;
