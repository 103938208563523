import {DeleteOutlined, SettingOutlined, PlusOutlined} from '@ant-design/icons';
import MyBarner from '@app/layout/barner/MyBarner';
import {Button, Space, Table, Row, Col, Typography} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import ConfirmActionModal from '@app/components/Modal/ConfirmActionModal';
import ReseconModal from '@app/components/Modal/ReseconModal/ReseconModal';
import Loading from '@app/components/Loading/Loading';
import {useIsDemo} from '@app/hooks/useIsDemo';
import {useGetReseconsQuery} from '@app/redux/services/reseconService';
import {useReseconList} from './useReseconList';
import {TResecon} from '@app/types';

const {Text} = Typography;

const ReseconList: FC = () => {
  const {t} = useTranslation();
  const isDemo = useIsDemo();
  const {isLoading, data: reseconData} = useGetReseconsQuery();
  const [state, actions] = useReseconList();

  const columns: ColumnsType<TResecon> = [
    {
      title: t('Receipt computer name'),
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('Ip address'),
      dataIndex: 'ip_address',
      key: 'ip_address',
      width: '15%',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('Login name'),
      dataIndex: 'username',
      key: 'username',
      width: '15%',
      responsive: ['md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('Share name'),
      dataIndex: 'share_name',
      key: 'share_name',
      width: '15%',
      responsive: ['md', 'lg', 'xl', 'xxl'],
    },
    {
      title: t('Path'),
      dataIndex: 'share_folder',
      key: 'share_folder',
      width: '15%',
      responsive: ['md', 'lg', 'xl', 'xxl'],
    },
    {
      width: '15%',
      render: (record: {id: number}) => (
        <Space>
          <Button
            onClick={() => actions.onDetailModalOpen(record.id)}
            icon={<SettingOutlined />}
          >
            <Text className="btn-text">{t('Edit')}</Text>
          </Button>
          <Button
            onClick={() => actions.onDeleteModalOpen(record.id)}
            icon={<DeleteOutlined />}
            danger
          >
            <Text type="danger" className="btn-text">
              {t('Delete')}
            </Text>
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <MyBarner />
      <ReseconModal
        open={state.isDetailModalOpen}
        onCancel={actions.onDetailModalClose}
        reseconId={state.selectedReseconId}
      />
      {state.isDeleting && <Loading />}
      <Row gutter={[8, 8]} justify="end">
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => actions.onDetailModalOpen()}
            disabled={isDemo}
          >
            {t('Receipt computer registration')}
          </Button>
        </Col>
        <Table
          style={{width: '100%'}}
          dataSource={reseconData}
          columns={columns}
          rowKey={(row) => row.id}
          loading={isLoading}
          pagination={false}
          locale={{
            emptyText: (
              <div style={{textAlign: 'left'}}>
                {t(
                  'Please configure the receipt computer connection settings from the initial setup button.',
                )}
              </div>
            ),
          }}
        />
      </Row>
      <ConfirmActionModal
        open={state.isDeleteModalOpen}
        onConfirm={actions.handleDeleteResecon}
        onCancel={actions.onDeleteModalClose}
        text={t('Is it okay to delete this receipt computer information?')}
      />
    </>
  );
};

export default ReseconList;
