import baseQueryWithReauth from '@app/helper/baseQueryWithReAuth';
import {TStaff, TStaffCreate, TStaffList, TStaffUpdate} from '@app/types';
import {createApi} from '@reduxjs/toolkit/query/react';

export const apiStaffService = createApi({
  reducerPath: 'staffService',
  tagTypes: ['Staff'],
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getStaffs: builder.query<TStaffList, void>({
        query: () => '/staffs',
        providesTags: (result) =>
          result
            ? [
                ...result.data.map(({id}) => ({
                  type: 'Staff' as const,
                  id,
                })),
                'Staff',
              ]
            : ['Staff'],
      }),
      getStaffById: builder.query<TStaff, number>({
        query: (id) => `/staffs/${id}`,
      }),
      createStaff: builder.mutation<TStaff, TStaffCreate>({
        query: (staff) => ({
          url: '/staffs',
          method: 'POST',
          body: staff,
        }),
        invalidatesTags: [{type: 'Staff'}],
      }),
      updateStaff: builder.mutation<TStaff, TStaffUpdate>({
        query: ({id, ...body}) => ({
          url: `/staffs/${id}`,
          method: 'PATCH',
          body: body,
        }),
        invalidatesTags: (result, error, arg) => [{type: 'Staff', id: arg.id}],
      }),
      deleteStaff: builder.mutation<null, number>({
        query: (staffId) => ({
          url: `/staffs/${staffId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [{type: 'Staff'}],
      }),
    };
  },
});

export const {
  useGetStaffsQuery,
  useGetStaffByIdQuery,
  useLazyGetStaffByIdQuery,
  useCreateStaffMutation,
  useUpdateStaffMutation,
  useDeleteStaffMutation,
} = apiStaffService;
