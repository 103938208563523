import {FC, useState} from 'react';
import SendConfirmationModal from './SendConfirmationModal';
import InputConfirmationModal from './InputConfirmationModal';

interface IEmailChangeModalProps {
  open: boolean;
  onCancel: () => void;
}

const EmailChangeModal: FC<IEmailChangeModalProps> = ({open, onCancel}) => {
  const [isConfirmationCodeSentSuccess, setConfirmationCodeSentSuccess] =
    useState(false);

  const handleSendConfirmationSuccess = () => {
    onCancel();
    setConfirmationCodeSentSuccess(true);
  };

  const handleInputConfirmationSuccess = () => {
    setConfirmationCodeSentSuccess(false);
    onCancel();
  };

  return (
    <>
      <SendConfirmationModal
        open={open}
        onSubmit={handleSendConfirmationSuccess}
        onCancel={onCancel}
      />
      <InputConfirmationModal
        open={isConfirmationCodeSentSuccess}
        onSubmit={handleInputConfirmationSuccess}
        onCancel={handleInputConfirmationSuccess}
      />
    </>
  );
};

export default EmailChangeModal;
