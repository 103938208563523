import {Form} from 'antd';
import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {TError, TUser} from '@app/types';
import {ErrorCodes} from '@app/constants';
import showNotification from '@app/utils/showNotification';
import {
  useGetMeQuery,
  useLazyGetMeQuery,
  useUpdateMeMutation,
} from '@app/redux/services/userService';
import {useUpdateOwnPharmacyMutation} from '@app/redux/services/pharmacyService';
import {useUpdateOwnCompanyMutation} from '@app/redux/services/companyService';
import {updateUserInfo} from '@app/redux/slices/userSlice';

type TFormType = 'user' | 'pharmacy' | 'company';

export const useRegistrationInfoForm = () => {
  const [form] = Form.useForm<TUser>();
  const dispatch = useDispatch();

  const {t} = useTranslation();

  const {data} = useGetMeQuery();
  const [getMe] = useLazyGetMeQuery();
  const [updateMe, {isLoading: isUpdatingMe}] = useUpdateMeMutation();
  const [updatePharmacy, {isLoading: isUpdatingPharmacy}] =
    useUpdateOwnPharmacyMutation();
  const [updateCompany, {isLoading: isUpdatingCompany}] =
    useUpdateOwnCompanyMutation();
  const [isEditing, setIsEditing] = useState<Record<TFormType, boolean>>({
    user: false,
    pharmacy: false,
    company: false,
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({...data});
    }
  }, [data]);

  const handleUpdateAccount = async () => {
    try {
      const {full_name, full_name_kana, department, position} =
        await form.validateFields([
          'full_name',
          'full_name_kana',
          'department',
          'position',
        ]);

      try {
        await updateMe({
          full_name,
          full_name_kana,
          department,
          position,
        }).unwrap();

        const user = await getMe().unwrap();
        dispatch(updateUserInfo(user));

        showNotification(
          t('Success'),
          t('Successfully updated!'),
          'bottomRight',
          'success',
        );
        setIsEditing((prev) => ({...prev, user: false}));
      } catch (error) {
        const {data: errorData} = error as TError;
        const errorMessage =
          errorData?.error_code === ErrorCodes.emailAlreadyRegisteredError
            ? errorData.message
            : t('An error occurred during the update!');

        showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
      }
    } catch (error) {
      return;
    }
  };

  const handleUpdatePharmacy = async () => {
    try {
      const {pharmacy} = await form.validateFields(['pharmacy'], {
        recursive: true,
      });

      try {
        await updatePharmacy({...pharmacy}).unwrap();
        showNotification(
          t('Success'),
          t('Successfully updated!'),
          'bottomRight',
          'success',
        );
        setIsEditing((prev) => ({...prev, pharmacy: false}));
      } catch (error) {
        const {data: errorData} = error as TError;
        const errorMessage = [
          ErrorCodes.facilityCodeAlreadyRegisteredError,
          ErrorCodes.phoneNumberAlreadyRegisteredError,
        ].includes(errorData?.error_code)
          ? errorData.message
          : t('An error occurred during the update!');

        showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
      }
    } catch (error) {
      return;
    }
  };

  const handleUpdateCompany = async () => {
    try {
      const {company} = await form.validateFields(['company'], {
        recursive: true,
      });
      try {
        await updateCompany({...company}).unwrap();
        showNotification(
          t('Success'),
          t('Successfully updated!'),
          'bottomRight',
          'success',
        );
        setIsEditing((prev) => ({...prev, company: false}));
      } catch (error) {
        showNotification(
          t('Error'),
          t('An error occurred during the update!'),
          'bottomRight',
          'error',
        );
      }
    } catch (error) {
      return;
    }
  };

  const onSubmit = async (type: TFormType) => {
    switch (type) {
      case 'user':
        await handleUpdateAccount();
        break;
      case 'pharmacy':
        await handleUpdatePharmacy();
        break;
      case 'company':
        await handleUpdateCompany();
        break;
    }
  };

  const onCancel = () => {
    setIsEditing((prev) => ({
      ...prev,
      user: false,
      pharmacy: false,
      company: false,
    }));
  };

  const onEdit = (type: TFormType) => {
    setIsEditing((prev) => ({...prev, [type]: true}));
  };

  return {
    form,
    isEditing,
    isLoading: isUpdatingMe || isUpdatingPharmacy || isUpdatingCompany,
    onSubmit,
    onCancel,
    onEdit,
  };
};

export default useRegistrationInfoForm;
