import {useEffect, useState} from 'react';
import {useForm} from 'antd/es/form/Form';
import {
  EPaymentInterval,
  EInvoiceDeliveryMethod,
  ErrorCodes,
} from '@app/constants';
import {useGetPaymentInvoiceQuery} from '@app/redux/services/paymentService';
import showNotification from '@app/utils/showNotification';
import {
  useCreatePaymentInvoiceMutation,
  useUpdatePaymentInvoiceMutation,
} from '@app/redux/services/paymentService';
import {useGetMeQuery} from '@app/redux/services/userService';
import {t} from 'i18next';
import {useLazyGetMeQuery} from '@app/redux/services/userService';
import {useAppDispatch} from '@app/redux/reduxHooks';
import {updateUserInfo} from '@app/redux/slices/userSlice';
import {TError, TInvoiceCreate} from '@app/types';

export const usePaymentInvoiceForm = () => {
  const [form] = useForm<Omit<TInvoiceCreate, 'delivery_methods'>>();
  const {data: me} = useGetMeQuery();
  const {data: invoice} = useGetPaymentInvoiceQuery();
  const [getUserMe] = useLazyGetMeQuery();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);

  const [createPaymentInvoice, {isLoading: isCreatingPaymentInvoice}] =
    useCreatePaymentInvoiceMutation();
  const [updatePaymentInvoice, {isLoading: isUpdatingPaymentInvoice}] =
    useUpdatePaymentInvoiceMutation();

  const [selectedMethods, setSelectedMethods] = useState<
    EInvoiceDeliveryMethod[]
  >([EInvoiceDeliveryMethod.email]);

  const onSelectMethod = (method: EInvoiceDeliveryMethod) => {
    if (!selectedMethods.includes(method)) {
      setSelectedMethods((prev) => [...prev, method]);
    } else if (selectedMethods.length > 1) {
      setSelectedMethods((prev) => prev.filter((item) => item !== method));
    }
  };

  const onSubmit = async () => {
    const values = await form.validateFields();
    try {
      if (invoice) {
        await updatePaymentInvoice({
          ...values,
          delivery_methods: selectedMethods,
        }).unwrap();
      } else {
        await createPaymentInvoice({
          ...values,
          delivery_methods: selectedMethods,
        }).unwrap();
        const user = await getUserMe().unwrap();
        if (user) {
          dispatch(updateUserInfo(user));
        }
      }
      const successMessage = invoice
        ? t('Successfully updated!')
        : t('Successfully registered!');
      showNotification(t('Success'), successMessage, 'bottomRight', 'success');
      setIsEditing(false);
    } catch (error) {
      const {data: errorData} = error as TError;
      const errorMessage = [
        ErrorCodes.paymentAlreadyCompletedError,
        ErrorCodes.paymentFailedError,
        ErrorCodes.paymentNotCompletedYetError,
        ErrorCodes.invalidAddressError,
        ErrorCodes.invalidEmailError,
        ErrorCodes.invalidPhoneNumberError,
        ErrorCodes.invalidZipCodeError,
        ErrorCodes.invalidWebsiteUrlError,
      ].includes(errorData?.error_code)
        ? errorData.message
        : invoice
          ? t('An error occurred during the update!')
          : t('An error occurred during the registration!');
      showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      interval: me?.payment?.interval || EPaymentInterval.monthly,
      company_name: invoice?.company_name || me?.company.name,
      billing_name: invoice?.billing_name || me?.full_name,
      billing_name_kana: invoice?.billing_name_kana || me?.full_name_kana,
      department: invoice?.department || me?.department,
      position: invoice?.position || me?.position,
      zip_code: invoice?.zip_code || me?.company.zip_code,
      location: invoice?.location || me?.company.location,
      street: invoice?.street || me?.company.street,
      email: invoice?.email || me?.email,
      phone_number: invoice?.phone_number || me?.company.phone_number,
      representative_name: invoice?.representative_name,
      website: invoice?.website,
    });
    if (invoice?.delivery_methods) {
      setSelectedMethods(invoice?.delivery_methods);
    }
  }, [invoice, me]);

  return {
    form,
    isLoading: isCreatingPaymentInvoice || isUpdatingPaymentInvoice,
    onSubmit,
    selectedMethods,
    onSelectMethod,
    isEditing,
    setIsEditing,
  };
};
