import {store} from '@app/redux/store';
import Router from '@app/router/Router';
import '@app/styles/App.scss';
import {Provider} from 'react-redux';

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

export default App;
