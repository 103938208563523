import {usePharmacyList} from './usePharmacyList';
import MyBarner from '@app/layout/barner/MyBarner';
import {Button, Col, Row, Table, Tag} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import PharmacyDetail from '@app/components/Modal/PharmacyModal/PharmacyDetail';
import {EPharmacyStatus} from '@app/constants';
import {MailOutlined, PlusOutlined} from '@ant-design/icons';
import PharmacyInvite from '@app/components/Modal/PharmacyModal/PharmacyInvite';
import {useGetPharmaciesQuery} from '@app/redux/services/pharmacyService';
import {useSearchParams} from 'react-router-dom';

const PharmacyList: FC = () => {
  const {t} = useTranslation();
  const [searchParams] = useSearchParams();
  const {isLoading, data: pharmacyList} = useGetPharmaciesQuery({
    page: searchParams.get('page')
      ? Number(searchParams.get('page'))
      : undefined,
    limit: searchParams.get('limit')
      ? Number(searchParams.get('limit'))
      : undefined,
  });
  const [state, actions] = usePharmacyList();

  const columns = [
    {
      title: t('Email'),
      dataIndex: ['manager', 'email'],
      key: 'manager.email',
      width: '15%',
      textWrap: 'word-break',
      render: (email: string) => <b style={{color: '#0665ac'}}>{email}</b>,
    },
    {
      title: t('Pharmacy name'),
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      textWrap: 'word-break',
    },
    {
      title: t('Phone number'),
      dataIndex: 'phone_number',
      key: 'phone_number',
      width: '15%',
      textWrap: 'word-break',
    },
    {
      title: t('Pharmacy admin name'),
      dataIndex: ['manager', 'full_name'],
      key: 'manager.full_name',
      width: '15%',
      textWrap: 'word-break',
    },
    {
      title: t('Address'),
      dataIndex: 'address',
      key: 'address',
      width: '15%',
      textWrap: 'word-break',
      render: (_text: unknown, record: {location: string; street: string}) => (
        <span>
          {record.location} {record.street}
        </span>
      ),
    },
    {
      title: t('Status'),
      key: 'status',
      width: '10%',
      textWrap: 'word-break',
      render: (_text: unknown, record: {status: EPharmacyStatus}) => {
        switch (record.status) {
          case EPharmacyStatus.active:
            return <Tag color="green">{t('In use')}</Tag>;
          case EPharmacyStatus.receiptComputerNotConnected:
            return (
              <Tag color="geekblue">
                {t('Receipt computer configuration in progress')}
              </Tag>
            );
          case EPharmacyStatus.passwordNotSet:
            return <Tag color="gold">{t('Initial setup in progress')}</Tag>;
          case EPharmacyStatus.invited:
            return <Tag color="gold">{t('Initial setup in progress')}</Tag>;
          default:
            return <Tag color="red">{t('Inactive')}</Tag>;
        }
      },
    },
    {
      title: t('Request email'),
      key: 'button',
      width: '10%',
      textWrap: 'word-break',
      render: (
        _text: unknown,
        record: {status: EPharmacyStatus; id: number},
      ) => (
        <Button
          type="primary"
          disabled={
            ![EPharmacyStatus.invited, EPharmacyStatus.passwordNotSet].includes(
              record.status,
            )
          }
          onClick={() => actions.resend(record.id)}
        >
          {t('Resend')}
        </Button>
      ),
    },
  ];

  const clickableColumns = columns.map((column) => {
    if (column.key !== 'button') {
      return {
        ...column,
        onCell: (record: {id: number}) => ({
          onClick: () => actions.onDetailModelOpen(record.id),
        }),
      };
    }
    return column;
  });

  return (
    <>
      <MyBarner />
      <PharmacyDetail
        pharmacyId={state.selectedPharmacyId}
        open={state.isDetailModalOpen}
        onCancel={actions.onDetailModalClose}
      />
      <PharmacyInvite
        open={state.isInviteModalOpen}
        onCancel={actions.onInviteModalClose}
      />

      <Row gutter={[8, 8]} justify={'end'}>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => actions.onDetailModelOpen()}
          >
            {t('Pharmacy registration')}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<MailOutlined />}
            onClick={actions.onInviteModalOpen}
          >
            {t('Request pharmacy registration via email')}
          </Button>
        </Col>
        {/* <Col>
          <Button type="primary" icon={<UploadOutlined />} disabled={true}>
            {t('Bulk upload')}
          </Button>
        </Col> */}
        <Table
          style={{width: '100%'}}
          dataSource={pharmacyList?.data}
          columns={clickableColumns}
          pagination={{
            total: pharmacyList?.meta.total_items,
            pageSize: pharmacyList?.meta.items_per_page,
            current: Number(searchParams.get('page') ?? '1'),
            position: ['bottomRight'],
            hideOnSinglePage: false,
          }}
          loading={isLoading}
          rowKey={(item) => item.id}
          onChange={actions.onTableChange}
        />
      </Row>
    </>
  );
};

export default PharmacyList;
