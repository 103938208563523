import baseQueryWithReauth from '@app/helper/baseQueryWithReAuth';
import {TManualUrls} from '@app/types';
import {createApi} from '@reduxjs/toolkit/query/react';

export const apiSettingService = createApi({
  reducerPath: 'settingService',
  tagTypes: ['Manual'],
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getManuals: builder.query<TManualUrls, void>({
        query: () => '/manuals',
        providesTags: ['Manual'],
      }),
    };
  },
});

export const {useGetManualsQuery, useLazyGetManualsQuery} = apiSettingService;
