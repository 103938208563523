import {useUnsubscribeMutation} from '@app/redux/services/paymentService';
import showNotification from '@app/utils/showNotification';
import {Form} from 'antd';
import {useTranslation} from 'react-i18next';

export const useUnsubscribeForm = () => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [unsubscribe, {isLoading}] = useUnsubscribeMutation();

  const onUnsubscribePlan = async () => {
    const {reason} = await form.validateFields();

    try {
      await unsubscribe({reason}).unwrap();
      form.resetFields();
      showNotification(
        t('Success'),
        t('Cancellation request has been submitted.'),
        'bottomRight',
        'success',
      );
    } catch (error) {
      showNotification(
        t('Error'),
        t('An error occurred while submitting the cancellation request.'),
        'bottomRight',
        'error',
      );
    }
  };
  return {
    form,
    isLoading,
    onUnsubscribePlan,
  };
};
