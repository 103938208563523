import {createApi} from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from '@app/helper/baseQueryWithReAuth';
import {
  TFile,
  TFileCreate,
  TFileUpdate,
  TInquiry,
  TInquiryCreate,
} from '@app/types';

export const apiAccountService = createApi({
  reducerPath: 'accountService',
  tagTypes: ['Account', 'Card', 'Invoice'],
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      sendInquiry: builder.mutation<TInquiry, TInquiryCreate>({
        query: (body) => ({
          url: `inquiries`,
          method: 'POST',
          body,
        }),
      }),
      batchCreateFile: builder.mutation<TFile[], TFileCreate[]>({
        query: (body) => ({
          url: `files`,
          method: 'POST',
          body,
        }),
      }),
      batchUpdateFile: builder.mutation<TFile[], TFileUpdate[]>({
        query: (body) => ({
          url: `files`,
          method: 'PATCH',
          body,
        }),
      }),
    };
  },
});

export const {
  useSendInquiryMutation,
  useBatchCreateFileMutation,
  useBatchUpdateFileMutation,
} = apiAccountService;
