import {Spin} from 'antd';
import {FC} from 'react';

const Loading: FC = () => {
  return (
    <div className="loading">
      <Spin size={'large'} />
    </div>
  );
};

export default Loading;
