import baseQueryWithReauth from '@app/helper/baseQueryWithReAuth';
import {TCompanyUpdateOwn} from '@app/types';
import {createApi} from '@reduxjs/toolkit/query/react';

export const apiCompanyService = createApi({
  reducerPath: 'companyService',
  tagTypes: ['Company'],
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      updateOwnCompany: builder.mutation<TCompanyUpdateOwn, TCompanyUpdateOwn>({
        query: (body) => ({
          url: `/companies/own`,
          method: 'PATCH',
          body: body,
        }),
        invalidatesTags: ['Company'],
      }),
    };
  },
});

export const {useUpdateOwnCompanyMutation} = apiCompanyService;
