import {Routes} from '@app/router/Routes';
import {validationRegex} from '@app/utils/validationRegex';
import {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

const usePasswordSearchParams = () => {
  const [searchParams] = useSearchParams();
  const cognitoUsername = searchParams.get('cognito_username') ?? '';
  const email = searchParams.get('email') ?? '';
  const temporaryPassword = searchParams.get('temporary_password') ?? '';
  const navigate = useNavigate();

  useEffect(() => {
    if (!cognitoUsername || !email || !temporaryPassword) {
      navigate(Routes.Login);
      return;
    }

    if (
      !validationRegex.cognitoUsername.test(cognitoUsername) ||
      !validationRegex.email.test(email) ||
      !validationRegex.temporaryPassword.test(temporaryPassword)
    ) {
      navigate(Routes.Login);
      return;
    }
  }, [cognitoUsername, email, temporaryPassword]);

  return {
    cognitoUsername,
    email,
    temporaryPassword,
  };
};

export default usePasswordSearchParams;
