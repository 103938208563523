import usePasswordSearchParams from '@app/hooks/usePasswordSearchParams';
import {useSetPasswordMutation} from '@app/redux/services/userService';
import {Routes} from '@app/router/Routes';
import {TError, TPassword} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {Form} from 'antd';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const useSetPasswordForm = () => {
  const {t} = useTranslation();
  const [form] = Form.useForm<TPassword>();
  const [setPassword, {isLoading}] = useSetPasswordMutation();
  const navigate = useNavigate();
  const {cognitoUsername, email, temporaryPassword} = usePasswordSearchParams();

  const onSubmit = async () => {
    const {password} = await form.validateFields();
    try {
      await setPassword({
        cognito_username: cognitoUsername,
        temporary_password: temporaryPassword,
        password,
      }).unwrap();
      showNotification(
        t('Success'),
        t(
          'Password successfully set. You can now log in with your new credentials.',
        ),
        'top',
        'success',
      );
      navigate(Routes.Login);
    } catch (error) {
      const {data: errorData} = error as TError;
      showNotification(
        t('Error'),
        t(errorData?.message) ??
          t('An error occurred while setting the password!'),
        'bottomRight',
        'error',
      );
    }
  };

  const initialValues = useMemo(() => {
    return {
      email,
    };
  }, [email]);

  return {
    form,
    initialValues,
    onSubmit,
    isLoading,
  };
};

export default useSetPasswordForm;
