import Loading from '@app/components/Loading/Loading';
import {IUseStaffForm, useStaffForm} from './useStaffForm';

import {Button, Form, Input, Modal} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {CONSTANTS} from '@app/constants';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';

interface StaffModalProps extends IUseStaffForm {
  open: boolean;
}

const StaffModal: FC<StaffModalProps> = ({staffId, open, onCancel}) => {
  const {t} = useTranslation();
  const {form, onSubmit, isLoading} = useStaffForm({
    staffId,
    onCancel,
  });
  const isCreating = !staffId;
  const {requiredOnly, passwordRules, passwordConfirmationRules} =
    useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={isCreating ? t('User registration') : t('Edit user information')}
      footer={null}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
        labelCol={{span: 8, offset: 2}}
        wrapperCol={{span: 12}}
        labelAlign="left"
        requiredMark={isCreating}
      >
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="username"
          label={t('User ID')}
          rules={requiredOnly({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.username}
            placeholder={t('Placeholders.Username')}
          />
        </Form.Item>
        <Form.Item
          name="full_name"
          label={t('Full name')}
          rules={requiredOnly({isRequired: isCreating})}
        >
          <Input
            maxLength={inputMaxLength.fullName}
            placeholder={t('Placeholders.Full name')}
          />
        </Form.Item>
        <Form.Item
          name={CONSTANTS.PASSWORD_FORM_FIELD_NAME}
          label={t('Password')}
          rules={passwordRules({isRequired: isCreating})}
        >
          <Input.Password
            maxLength={inputMaxLength.password}
            placeholder={t('Placeholders.Password')}
          />
        </Form.Item>
        <Form.Item
          name={CONSTANTS.PASSWORD_CONFIRMATION_FORM_FIELD_NAME}
          label={t('Re-enter password')}
          messageVariables={{labelMatch: t('Password')}}
          rules={passwordConfirmationRules({isRequired: isCreating})}
        >
          <Input.Password
            maxLength={inputMaxLength.password}
            placeholder={t('Placeholders.Password')}
          />
        </Form.Item>
        {isLoading && <Loading />}
        <div style={{textAlign: 'center'}}>
          <Button type="primary" htmlType="submit">
            {isCreating ? t('Register') : t('Save')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default StaffModal;
