import {useForgotPasswordMutation} from '@app/redux/services/userService';
import showNotification from '@app/utils/showNotification';
import {Form} from 'antd';
import {Routes} from '@app/router/Routes';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useMemo} from 'react';
import {TForgotPassword} from '@app/types';

export const useForgotPasswordForm = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm<TForgotPassword>();
  const [forgotPassword, {isLoading}] = useForgotPasswordMutation();
  const [searchParams] = useSearchParams();

  const onSubmit = async () => {
    const {email} = await form.validateFields(['email']);

    try {
      await forgotPassword({email});
      showNotification(
        t('Success'),
        t(
          'An email for resetting your password has been sent to the email address you provided.',
        ),
        'bottomRight',
        'success',
      );
      navigate(Routes.ForgotPasswordConfirm + `?email=${email}`);
    } catch (error) {
      showNotification(
        t('Error'),
        t('An error has occurred.'),
        'bottomRight',
        'error',
      );
    }
  };

  const initialValues = useMemo(() => {
    return {
      email: searchParams.get('email') || '',
    };
  }, [searchParams]);

  return {
    form,
    initialValues,
    onSubmit,
    isLoading,
  };
};
