import {CONSTANTS} from '@app/constants';
import {getToken} from '@app/middleware/getToken';
import {clearUser, setToken} from '@app/redux/slices/userSlice';
import {IRefreshTokenResponse} from '@app/types';
import {QueryReturnValue} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
const baseQuery = fetchBaseQuery({
  baseUrl: CONSTANTS.API_URL,
  prepareHeaders(headers) {
    const token = getToken()?.id_token;
    if (token) headers.set('Authorization', token);

    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const oldTokenInfo = getToken();

    if (oldTokenInfo) {
      const refreshResult: QueryReturnValue<
        unknown,
        FetchBaseQueryError,
        FetchBaseQueryMeta
      > = await baseQuery(
        {
          method: 'POST',
          url: '/refresh_token',
          body: {
            refresh_token: oldTokenInfo.refresh_token,
          },
        },
        api,
        {...extraOptions, maxRetries: 1},
      );
      const refreshTokenData = refreshResult.data as IRefreshTokenResponse;
      if (refreshTokenData) {
        // store the new toke
        const newTokenInfo = {
          ...oldTokenInfo,
          ...refreshTokenData,
        };
        api.dispatch(setToken(newTokenInfo));

        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(clearUser());
      }
    } else {
      api.dispatch(clearUser());
    }
  }
  return result;
};

export default baseQueryWithReauth;
