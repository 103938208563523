import {FC} from 'react';
import {PhoneOutlined, UserOutlined, LockOutlined} from '@ant-design/icons';
import {Form, Input, Button, Tabs, Typography, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Loading from '@app/components/Loading/Loading';
import useLoginForm from '@app/pages/Login/useLoginForm';
import {Routes} from '@app/router/Routes';
import type {TabsProps} from 'antd';
import {CONSTANTS} from '@app/constants';
import AuthLogo from '@app/components/Logo/AuthLogo';
import {useFormValidationRules} from '@app/utils/formValidationRules';

const {Text} = Typography;

const Login: FC = () => {
  const {t} = useTranslation();
  const {isLoading, onFinish} = useLoginForm();
  const {requiredOnly} = useFormValidationRules();

  const renderLoginForm = (type: string) => (
    <Form onFinish={onFinish} autoComplete="off" style={{height: '40vh'}}>
      {type === 'user' && (
        <Form.Item
          name="phone_number"
          rules={requiredOnly({isLabelAliased: true})}
          messageVariables={{labelAlias: t('Phone number')}}
        >
          <Input
            prefix={<PhoneOutlined />}
            placeholder={t('Pharmacy phone number')}
          />
        </Form.Item>
      )}
      <Form.Item
        name="username"
        rules={requiredOnly({isLabelAliased: true})}
        messageVariables={{labelAlias: t('User ID')}}
      >
        <Input prefix={<UserOutlined />} placeholder={t('User ID')} />
      </Form.Item>
      <Form.Item
        name={CONSTANTS.PASSWORD_FORM_FIELD_NAME}
        rules={requiredOnly({isLabelAliased: true})}
        messageVariables={{labelAlias: t('Password')}}
      >
        <Input.Password prefix={<LockOutlined />} placeholder={t('Password')} />
      </Form.Item>
      <Form.Item>
        <Row justify="space-between" align="middle">
          <Button type="link" htmlType="button">
            <Link to={Routes.ForgotPassword}>{t('Forgot password')}</Link>
          </Button>
        </Row>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="ant-btn-primary ant-btn-lg ant-btn-block base-form-button"
        >
          {t('Login')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Text>{t('Or')}</Text>
        <Button type="link" htmlType="button">
          <Link to={Routes.Registration}>{t('Sign Up')}</Link>
        </Button>
      </Form.Item>
    </Form>
  );

  const forms: TabsProps['items'] = [
    {
      key: 'user',
      label: t('Regular user'),
      children: renderLoginForm('user'),
    },
    {
      key: 'admin',
      label: t('Admin'),
      children: renderLoginForm('admin'),
    },
  ];

  const tabs = (
    <Tabs type="card" defaultActiveKey="admin" items={forms} tabBarGutter={5} />
  );

  return (
    <>
      {isLoading && <Loading />}
      <AuthLogo title={t('Login page')} content={tabs} />
    </>
  );
};

export default Login;
