import Loading from '@app/components/Loading/Loading';
import {SendOutlined} from '@ant-design/icons';
import {useUnsubscribeForm} from '@app/pages/Unsubscribe/useUnsubscribeForm';
import {Button, Card, Form, Input} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormValidationRules} from '@app/utils/formValidationRules';

const Unsubscribe: FC<unknown> = () => {
  const {t} = useTranslation();
  const {form, onUnsubscribePlan, isLoading} = useUnsubscribeForm();
  const {requiredOnly} = useFormValidationRules();

  return (
    <Card title={t('Unsubscribe request form')}>
      <Form
        layout="vertical"
        form={form}
        labelAlign="left"
        onFinish={onUnsubscribePlan}
      >
        {isLoading && <Loading />}
        <Form.Item
          label={t('Unsubscribe reason')}
          name="reason"
          required
          rules={requiredOnly()}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <Button
            icon={<SendOutlined />}
            type="primary"
            size="large"
            htmlType={'submit'}
            className="ant-btn-primary ant-btn-lg"
          >
            {t('Unsubscribe')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Unsubscribe;
