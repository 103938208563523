import {apiAccountService} from '@app/redux/services/accountService';
import {auditService} from '@app/redux/services/auditService';
import {apiReseconService} from '@app/redux/services/reseconService';
import {apiUserService} from '@app/redux/services/userService';
import {apiStaffService} from '@app/redux/services/staffService';
import {apiPharmacyService} from '@app/redux/services/pharmacyService';
import {apiCompanyService} from '@app/redux/services/companyService';
import {apiPaymentService} from '@app/redux/services/paymentService';
import {apiSettingService} from '@app/redux/services/settingService';
import userReducer, {logout} from '@app/redux/slices/userSlice';
import {AnyAction, combineReducers, configureStore} from '@reduxjs/toolkit';

const appReducer = combineReducers({
  user: userReducer,
  [apiUserService.reducerPath]: apiUserService.reducer,
  [auditService.reducerPath]: auditService.reducer,
  [apiAccountService.reducerPath]: apiAccountService.reducer,
  [apiReseconService.reducerPath]: apiReseconService.reducer,
  [apiStaffService.reducerPath]: apiStaffService.reducer,
  [apiCompanyService.reducerPath]: apiCompanyService.reducer,
  [apiPharmacyService.reducerPath]: apiPharmacyService.reducer,
  [apiPaymentService.reducerPath]: apiPaymentService.reducer,
  [apiSettingService.reducerPath]: apiSettingService.reducer,
});

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction,
) => {
  if (action.type === logout.fulfilled.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({serializableCheck: false}).concat([
      apiUserService.middleware,
      auditService.middleware,
      apiAccountService.middleware,
      apiReseconService.middleware,
      apiStaffService.middleware,
      apiCompanyService.middleware,
      apiPharmacyService.middleware,
      apiPaymentService.middleware,
      apiSettingService.middleware,
    ]);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
