import React from 'react';
import {Space, Tabs, Card, Typography, TabsProps, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {useIsDemo} from '@app/hooks/useIsDemo';
import PaymentCardForm from './PaymentCardForm';
import PaymentInvoiceForm from './PaymentInvoiceFrom';
import {usePaymentMethod} from '@app/hooks/usePaymentMethod';
import {useTranslation} from 'react-i18next';
import {EPaymentInterval, EPaymentMethod} from '@app/constants';

const {Text} = Typography;

interface PaymentPlanDataType {
  key: React.Key;
  plan: string;
  amount: number;
  description: string;
}

type TFormType = 'card' | 'invoice';

const PaymentInfo: React.FC<unknown> = () => {
  const {t} = useTranslation();
  const isDemo = useIsDemo();
  const paymentMethod = usePaymentMethod();

  const paymentPlanColumns: ColumnsType<PaymentPlanDataType> = [
    {
      title: 'Payment Plan',
      dataIndex: 'plan',
      key: 'plan',
    },
    {
      title: 'Payment Plan Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => (
        <span>
          {amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          {t('Yen')}／{t('Store')}
        </span>
      ),
    },
    {
      title: 'Payment Plan Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const paymentPlanData: PaymentPlanDataType[] = [
    {
      key: '1',
      plan: t(`Pay ${EPaymentInterval.monthly}`),
      amount: 11000,
      description: t(`Pay ${EPaymentInterval.monthly} Plan Description`),
    },
    {
      key: '2',
      plan: t(`Pay ${EPaymentInterval.annual}`),
      amount: 132000,
      description: t(`Pay ${EPaymentInterval.annual} Plan Description`),
    },
  ];

  const renderPaymentCard = (type: TFormType) => {
    return (
      <Space direction="vertical" size={24} style={{width: '100%'}}>
        {type === 'card' && (
          <Card bordered={false}>
            <PaymentCardForm />
          </Card>
        )}
        {type === 'invoice' && (
          <Card title={t('Payment Method Invoice')} bordered={false}>
            <PaymentInvoiceForm />
          </Card>
        )}
        {isDemo && (
          <>
            <Table
              columns={paymentPlanColumns}
              dataSource={paymentPlanData}
              showHeader={false}
              pagination={false}
              style={{width: '100%'}}
              bordered
            />
            <div>
              <Text>
                <span>{t('Price Description')}</span>
              </Text>
              <br />
              <Text>
                <span>{t('Usage Notice on iPhone')}</span>
              </Text>
            </div>
          </>
        )}
      </Space>
    );
  };

  const forms: TabsProps['items'] = [
    {
      key: EPaymentMethod.card,
      label: t('Payment Method Card'),
      children: renderPaymentCard('card'),
      disabled: paymentMethod === EPaymentMethod.invoice,
    },
    {
      key: EPaymentMethod.invoice,
      label: t('Payment Method Invoice'),
      children: renderPaymentCard('invoice'),
      disabled: paymentMethod === EPaymentMethod.card,
    },
  ];

  return (
    <>
      <div>
        <Tabs
          type="card"
          defaultActiveKey={isDemo ? 'card' : paymentMethod}
          items={forms}
          tabBarGutter={5}
          tabBarStyle={{border: '1px'}}
        />
      </div>
    </>
  );
};

export default PaymentInfo;
