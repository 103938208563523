import {CheckCircleFilled} from '@ant-design/icons';
import {CONSTANTS} from '@app/constants';
import MyHeader from '@app/layout/header/MyHeader';
import {Button, Result, Space} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {AppStoreIcon} from '@app/assets/icons';
import {Link} from 'react-router-dom';
import {QRCode} from 'antd';

const RegistrationFinished: FC = () => {
  const {t} = useTranslation();

  const extra = (
    <Space direction="vertical" size={24}>
      <Button type="link" style={{borderColor: 'lightgray', color: 'black'}}>
        <Link to={CONSTANTS.HOME_PAGE_URL}>{t('Return to the home page')}</Link>
      </Button>
      <Link to={CONSTANTS.APP_STORE_URL}>
        <AppStoreIcon />
      </Link>
      <div>
        {t(
          'As part of the preparations, please download OKUSURI-AI from the App Store.',
        )}
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <QRCode errorLevel="H" value={CONSTANTS.APP_STORE_URL} />
      </div>
    </Space>
  );
  return (
    <div>
      <MyHeader />
      <Result
        status="success"
        icon={<CheckCircleFilled />}
        title={t('Thank you for registering!')}
        subTitle={t(
          'We will contact you by email at a later date from our representative. Until then, we kindly ask for your patience and understanding. Thank you very much.',
        )}
        extra={extra}
      />
    </div>
  );
};

export default RegistrationFinished;
