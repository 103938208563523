import baseQueryWithReauth from '@app/helper/baseQueryWithReAuth';
import {
  TForgotPassword,
  TUser,
  TResetPassword,
  TSetPassword,
  TManager,
  TManagerUpdate,
  TRegisterRequest,
  TRegisterResponse,
  TInvitePharmacy,
  TCompletePharmacy,
  TOwnEmailUpdate,
  TOwnEmailConfirm,
  TOwnPasswordUpdate,
} from '@app/types';
import {createApi} from '@reduxjs/toolkit/query/react';

export const apiUserService = createApi({
  reducerPath: 'userService',
  tagTypes: ['Me'],
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      register: builder.mutation<TRegisterResponse, TRegisterRequest>({
        query: (body) => ({
          url: `/register`,
          method: 'POST',
          body,
        }),
      }),
      getMe: builder.query<TUser, void>({
        query() {
          return `/me`;
        },
        providesTags: ['Me'],
      }),
      updateMe: builder.mutation<TManager, TManagerUpdate>({
        query: (body) => ({
          url: `me`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['Me'],
      }),
      updateMeEmail: builder.mutation<void, TOwnEmailUpdate>({
        query: (body) => ({
          url: `me/email`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['Me'],
      }),
      confirmMeEmail: builder.mutation<void, TOwnEmailConfirm>({
        query: (body) => ({
          url: `me/email/confirm`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Me'],
      }),
      updateMePassword: builder.mutation<void, TOwnPasswordUpdate>({
        query: (body) => ({
          url: `/me/password`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: ['Me'],
      }),
      forgotPassword: builder.mutation<null, TForgotPassword>({
        query: (body) => ({
          url: `/forgot_password`,
          method: 'POST',
          body,
        }),
      }),
      resetPassword: builder.mutation<null, TResetPassword>({
        query: (body) => ({
          url: `/confirm_forgot_password`,
          method: 'POST',
          body,
        }),
      }),
      setPassword: builder.mutation<null, TSetPassword>({
        query: (body) => ({
          url: `/set_password`,
          method: 'POST',
          body,
        }),
      }),
      invitePharmacy: builder.mutation<null, TInvitePharmacy>({
        query: (body) => ({
          url: `/invite/pharmacy`,
          method: 'POST',
          body,
        }),
      }),
      completePharmacy: builder.mutation<
        Omit<TUser, 'company'>,
        TCompletePharmacy
      >({
        query: (body) => ({
          url: `/complete/pharmacy`,
          method: 'POST',
          body,
        }),
      }),
    };
  },
});

export const {
  useRegisterMutation,
  useLazyGetMeQuery,
  useGetMeQuery,
  useUpdateMeMutation,
  useUpdateMeEmailMutation,
  useConfirmMeEmailMutation,
  useUpdateMePasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSetPasswordMutation,
  useInvitePharmacyMutation,
  useCompletePharmacyMutation,
} = apiUserService;
