export const Routes = {
  Login: '/login',
  EventPublicRelations: '/event_public_relations',
  TermsOfService: '/terms_of_service',
  ForgotPassword: '/forgot_password',
  ForgotPasswordConfirm: '/confirm_forgot_password',
  SetPassword: '/set_password',
  PlanSelection: '/plan_selection',
  CompletePharmacyInvitation: '/complete_pharmacy_invitation',
  Registration: '/register',
  RegistrationFinished: '/register_finish',
  Home: '/',
  Subscription: '/app/subscription',
  AuditHistory: '/app/audit_history',
  AuditDetail: '/app/audit_detail',
  PharmacyList: '/app/pharmacy_list',
  PharmacyInfo: '/app/pharmacy_info',
  AccountSettings: '/app/account_settings',
  ReseconList: '/app/resecon_list',
  UserManagement: '/app/user_management',
  PaymentHistory: '/app/payment_history',
  CompanyInfo: '/app/company_info',
  PaymentInfo: '/app/payment_info',
  ManualInfo: '/app/user_guide',
  FormInquiry: '/app/form_inquiry',
  Contact: '/app/contact',
  TermsOfUse: '/app/terms_of_service',
  Unsubscribe: '/app/unsubscribe',
  UpdateSubscription: '/app/update-subscription',
};
