import Loading from '@app/components/Loading/Loading';

import useRegistrationInfoForm from '@app/pages/RegistrationInfo/useRegistrationInfoForm';
import {Button, Form, Row, Col, Space} from 'antd';
import {FC, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useIsCompanyManager, useIsPharmacyManager} from '@app/hooks/useIsAdmin';

import {EditOutlined} from '@ant-design/icons';
import UserInfoCard from '@app/components/FormCard/UserInfoCard';
import PharmacyInfoCard from '@app/components/FormCard/PharmacyInfoCard';
import CompanyInfoCard from '@app/components/FormCard/CompanyInfoCard';

import EmailChangeModal from '@app/components/Modal/EmailChangeModal/EmailChangeModal';
import PasswordChangeModal from '@app/components/Modal/PasswordChangeModal/PasswordChangeModal';

type TFormType = 'user' | 'pharmacy' | 'company';

const RegistrationInfo: FC = () => {
  const {t} = useTranslation();

  const isCompanyManager = useIsCompanyManager();
  const isPharmacyManager = useIsPharmacyManager();
  const {form, isEditing, isLoading, onSubmit, onCancel, onEdit} =
    useRegistrationInfoForm();

  const [isEmailChangeModalVisible, setEmailChangeModalVisible] =
    useState(false);
  const [isPasswordChangeModalVisible, setPasswordChangeModalVisible] =
    useState(false);

  const openEmailChangeModal = () => {
    setEmailChangeModalVisible(true);
  };

  const openPasswordChangeModal = () => {
    setPasswordChangeModalVisible(true);
  };

  const cardButton = useCallback(
    (type: TFormType) => (
      <Row justify="end">
        <Col>
          {isEditing[type] ? (
            <div>
              <Space>
                {type === 'user' && true && (
                  <Button danger onClick={openEmailChangeModal}>
                    {t('Own Email Edit')}
                  </Button>
                )}
                {type === 'user' && true && (
                  <Button danger onClick={openPasswordChangeModal}>
                    {t('Own Password Edit')}
                  </Button>
                )}
                <Button type="primary" onClick={() => onSubmit(type)}>
                  {t('Save')}
                </Button>
                <Button type="default" onClick={onCancel}>
                  {t('Cancel')}
                </Button>
              </Space>
            </div>
          ) : (
            <Space>
              <Button
                type="default"
                onClick={() => onEdit(type)}
                icon={<EditOutlined />}
              >
                {t('Edit')}
              </Button>
            </Space>
          )}
        </Col>
      </Row>
    ),
    [isEditing, onSubmit, onCancel, onEdit],
  );

  return (
    <>
      {isLoading && <Loading />}

      <Form form={form} layout="vertical">
        <Space direction="vertical" size={16} style={{width: '100%'}}>
          <UserInfoCard
            isDisabled={!isEditing.user}
            isEmailDisabled={true}
            isFieldsRequired={false}
            extra={cardButton('user')}
          />
          {isPharmacyManager && (
            <PharmacyInfoCard
              form={form}
              isDisabled={!isEditing.pharmacy}
              isFieldsRequired={false}
              extra={cardButton('pharmacy')}
            />
          )}
          {isCompanyManager && (
            <CompanyInfoCard
              form={form}
              isDisabled={!isEditing.company}
              isFieldsRequired={false}
              extra={cardButton('company')}
            />
          )}
        </Space>
      </Form>
      <EmailChangeModal
        open={isEmailChangeModalVisible}
        onCancel={() => {
          setEmailChangeModalVisible(false);
          onCancel();
        }}
      />
      <PasswordChangeModal
        open={isPasswordChangeModalVisible}
        onCancel={() => {
          setPasswordChangeModalVisible(false);
          onCancel();
        }}
      />
    </>
  );
};

export default RegistrationInfo;
