import {MailOutlined} from '@ant-design/icons';
import Loading from '@app/components/Loading/Loading';
import {Routes} from '@app/router/Routes';
import {Button, Form, Input, Typography} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {useForgotPasswordForm} from '@app/pages/ForgotPassword/useForgotPasswordForm';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';
import AuthLogo from '@app/components/Logo/AuthLogo';

const {Text} = Typography;
const ForgotPassword: FC = () => {
  const {t} = useTranslation();

  const {form, initialValues, onSubmit, isLoading} = useForgotPasswordForm();
  const {emailRules} = useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  const content = (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onSubmit}
      autoComplete="off"
    >
      <Form.Item
        name="email"
        rules={emailRules({isRequired: true, isLabelAliased: true})}
        messageVariables={{labelAlias: t('Email')}}
      >
        <Input
          maxLength={inputMaxLength.email}
          prefix={<MailOutlined />}
          placeholder={t('Email')}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="ant-btn-lg ant-btn-block"
        >
          {t('Next')}
        </Button>
      </Form.Item>
      <Text>{t('Or')}</Text>
      <Button type="link">
        <Link to={Routes.Login}>{t('Return to the login screen')}</Link>
      </Button>
    </Form>
  );

  return (
    <>
      {isLoading && <Loading />}

      <AuthLogo
        title={t('Password reset screen')}
        text={t(
          'To reset your password, please enter your registered email address.',
        )}
        content={content}
      />
    </>
  );
};

export default ForgotPassword;
