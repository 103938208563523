import baseQueryWithReauth from '@app/helper/baseQueryWithReAuth';
import {createApi} from '@reduxjs/toolkit/query/react';
import {
  TCardUpdate,
  TInvoice,
  TInvoiceCreate,
  TPaymentList,
  TPaginationArgs,
  TCardCreate,
  TCard,
  TInvoiceUpdate,
  TUnsubscribe,
} from '@app/types';

export const apiPaymentService = createApi({
  reducerPath: 'paymentService',
  tagTypes: ['Payment', 'Card', 'Invoice'],
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      createPaymentCard: builder.mutation<TCard, TCardCreate>({
        query: (body) => ({
          url: `/payments/card`,
          method: 'POST',
          body,
        }),
        invalidatesTags: [{type: 'Card'}],
      }),
      updatePaymentCard: builder.mutation<TCard, TCardUpdate>({
        query: (body) => ({
          url: `/payments/card`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: [{type: 'Card'}],
      }),
      getPaymentCard: builder.query<TCard, void>({
        query: () => ({
          url: '/payments/card',
          method: 'GET',
        }),
        providesTags: [{type: 'Card'}],
      }),
      createPaymentInvoice: builder.mutation<TInvoice, TInvoiceCreate>({
        query: (body) => {
          return {
            url: '/payments/invoice',
            method: 'POST',
            body,
          };
        },
        invalidatesTags: [{type: 'Invoice'}],
      }),
      updatePaymentInvoice: builder.mutation<TInvoice, TInvoiceUpdate>({
        query: (body) => {
          return {
            url: '/payments/invoice',
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: [{type: 'Invoice'}, {type: 'Payment'}],
      }),
      getPaymentInvoice: builder.query<TInvoice, void>({
        query: () => ({
          url: '/payments/invoice',
          method: 'GET',
        }),
        providesTags: [{type: 'Invoice'}],
      }),
      getPayments: builder.query<TPaymentList, TPaginationArgs>({
        query(args) {
          return {
            url: '/payments',
            params: args,
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.data.map(({id}) => ({
                  type: 'Payment' as const,
                  id,
                })),
                'Payment',
              ]
            : ['Payment'],
      }),
      downloadPayment: builder.mutation<void, {id: number}>({
        query: ({id}) => ({
          url: `/payments/${id}/download`,
          method: 'POST',
        }),
      }),
      unsubscribe: builder.mutation<void, TUnsubscribe>({
        query: (body) => ({
          url: `/payments/unsubscribe`,
          method: 'POST',
          body,
        }),
      }),
    };
  },
});

export const {
  useCreatePaymentCardMutation,
  useUpdatePaymentCardMutation,
  useGetPaymentCardQuery,
  useCreatePaymentInvoiceMutation,
  useUpdatePaymentInvoiceMutation,
  useGetPaymentInvoiceQuery,
  useGetPaymentsQuery,
  useDownloadPaymentMutation,
  useUnsubscribeMutation,
} = apiPaymentService;
