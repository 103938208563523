export const validationRegex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/,
  cognitoUsername: /[0-9a-z]{32}/,
  temporaryPassword: /[0-9a-zA-Z]{8}/,
  password: /^[A-Za-z0-9@$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]+$/,
  katakana: /^[ァ-ヴー \u3000]+$/u,
  zipCode: /^\d{7}$/,
  facilityCode: /^\d{7,10}$/,
  phoneNumber: /^\d{10,17}$/,
  ipAddress:
    /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})(\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})){3}$/,
  confirmationCode: /^\d{6}$/,
  website: /^https:\/\/www\.[\da-z.-]+\.[a-z.]{2,6}(\/[\w .-]*)*\/?$/i,
};
