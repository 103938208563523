import useGeolocation from '@app/hooks/useGeolocation';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';
import {Card, Col, Form, FormInstance, Input, Row} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface ICompanyInfoCardProps {
  form?: FormInstance;
  isDisabled?: boolean;
  isFieldsRequired?: boolean;
  extra?: JSX.Element;
  columnLayout?: {[key: string]: number};
}

const CompanyInfoCard: FC<ICompanyInfoCardProps> = ({
  form,
  isDisabled = false,
  isFieldsRequired = true,
  extra,
}) => {
  const {t} = useTranslation();
  const isRequired = isDisabled ? false : isFieldsRequired;
  const {isLoading, onSearch} = useGeolocation();
  const {requiredOnly, zipCodeRules, phoneNumberRules} =
    useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  const onSearchAddress = async (value: string) => {
    const result = await onSearch(value);
    form?.setFieldsValue({
      company: {
        location: result,
      },
    });
  };

  const FormItems = {
    name: (
      <Form.Item
        name={['company', 'name']}
        label={t('Company name')}
        rules={requiredOnly({isRequired})}
      >
        <Input
          maxLength={inputMaxLength.companyName}
          placeholder={isDisabled ? '' : t('Placeholders.Company name')}
          disabled={isDisabled}
        />
      </Form.Item>
    ),
    phoneNumber: (
      <Form.Item
        name={['company', 'phone_number']}
        label={t('Phone number')}
        rules={phoneNumberRules({isRequired: false})}
      >
        <Input
          maxLength={inputMaxLength.phoneNumber}
          placeholder={isDisabled ? '' : t('Placeholders.Company phone number')}
          disabled={isDisabled}
        />
      </Form.Item>
    ),
    zipCode: (
      <Form.Item
        name={['company', 'zip_code']}
        label={t('Postal code')}
        rules={zipCodeRules({isRequired})}
      >
        {isDisabled ? (
          <Input
            maxLength={inputMaxLength.zipCode}
            placeholder={isDisabled ? '' : t('Placeholders.Postal code')}
            disabled={isDisabled}
          />
        ) : (
          <Input.Search
            maxLength={inputMaxLength.zipCode}
            placeholder={isDisabled ? '' : t('Placeholders.Postal code')}
            loading={isLoading}
            onSearch={onSearchAddress}
            disabled={isDisabled}
          />
        )}
      </Form.Item>
    ),
    location: (
      <Form.Item
        name={['company', 'location']}
        label={t('Company location')}
        rules={requiredOnly({isRequired})}
      >
        <Input
          maxLength={inputMaxLength.location}
          placeholder={isDisabled ? '' : t('Placeholders.Company location')}
          disabled={isDisabled}
        />
      </Form.Item>
    ),
    street: (
      <Form.Item
        name={['company', 'street']}
        label={t('Street number and building name')}
        rules={requiredOnly({isRequired})}
      >
        <Input
          maxLength={inputMaxLength.street}
          placeholder={isDisabled ? '' : t('Placeholders.Company street')}
          disabled={isDisabled}
        />
      </Form.Item>
    ),
  };

  return (
    <Card
      title={t('Company information')}
      bordered
      className="form-card"
      size="small"
      extra={extra}
    >
      <Row gutter={24} align={'bottom'}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {FormItems.name}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {FormItems.phoneNumber}
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          {FormItems.zipCode}
        </Col>
        <Col xs={24} sm={24} md={24} lg={9} xl={9}>
          {FormItems.location}
        </Col>
        <Col xs={24} sm={24} md={24} lg={9} xl={9}>
          {FormItems.street}
        </Col>
      </Row>
    </Card>
  );
};

export default CompanyInfoCard;
