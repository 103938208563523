import {getToken} from '@app/middleware/getToken';
import {useAppDispatch} from '@app/redux/reduxHooks';
import {useLazyGetMeQuery} from '@app/redux/services/userService';
import {clearUser, updateUserInfo} from '@app/redux/slices/userSlice';
import {useEffect, useState} from 'react';

type UseAuthResult = {
  isLoading: boolean;
};

export const useAuth = (): UseAuthResult => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const [getUserMe] = useLazyGetMeQuery();

  useEffect(() => {
    const init = async () => {
      try {
        const token = getToken();
        if (token) {
          const user = await getUserMe().unwrap();
          if (user) {
            dispatch(updateUserInfo(user));
          } else {
            dispatch(clearUser());
          }
        } else {
          dispatch(clearUser());
        }
      } catch (error) {
        dispatch(clearUser());
      } finally {
        setIsLoading(false);
      }
    };
    init();
  }, [dispatch, getUserMe]);

  return {
    isLoading,
  };
};
