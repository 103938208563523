import {useUpdateMePasswordMutation} from '@app/redux/services/userService';
import {TError, TOwnPasswordUpdate, TUser} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {useEffect} from 'react';
import {Form} from 'antd';
import {t} from 'i18next';
import {ErrorCodes} from '@app/constants';

interface IUsePharmacyForm {
  user: TUser | undefined;
  onCancel: () => void;
}

type TUsePasswordChangeForm = {
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
};

export const useEmailChangeForm = ({user, onCancel}: IUsePharmacyForm) => {
  const [form] = Form.useForm<TUsePasswordChangeForm>();

  const [updateOwnPassword, {isLoading: isUpdatingOwnEmail}] =
    useUpdateMePasswordMutation();

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    }
  }, [user]);

  const onUpdate = async () => {
    const formValues = await form.validateFields();
    const {old_password, password} = formValues as TUsePasswordChangeForm;
    if (user) {
      await updateOwnPassword({
        password: old_password,
        new_password: password,
      } as TOwnPasswordUpdate)
        .unwrap()
        .then(() => {
          const successMessage = t('Successfully sent!');
          showNotification(
            t('Success'),
            successMessage,
            'bottomRight',
            'success',
          );
          form.resetFields();
          form.setFieldsValue(user);
          onCancel();
        })
        .catch((error: TError) => {
          const {data: errorData} = error;
          const errorMessage = [
            ErrorCodes.emailAlreadyRegisteredError,
          ].includes(errorData?.error_code)
            ? errorData.message
            : errorData?.error_code ===
                ErrorCodes.incorrectInputInformationError
              ? t(
                  'There is an error in password. Please check and enter it again.',
                )
              : t('An error occurred during the sending!');
          showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
        });
    }
  };

  return {
    form,
    isLoading: isUpdatingOwnEmail,
    onUpdate,
  };
};
