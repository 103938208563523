import {useDeleteReseconMutation} from '@app/redux/services/reseconService';
import showNotification from '@app/utils/showNotification';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface IUseReseconListState {
  selectedReseconId: number | undefined;
  isDetailModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isDeleting: boolean;
}

interface IUseReseconListActions {
  onDeleteModalOpen: (reseconId: number) => void;
  onDeleteModalClose: () => void;
  onDetailModalOpen: (reseconId?: number) => void;
  onDetailModalClose: () => void;
  handleDeleteResecon: () => Promise<void>;
}

export const useReseconList = (): [
  IUseReseconListState,
  IUseReseconListActions,
] => {
  const {t} = useTranslation();
  const [state, setState] = useState<IUseReseconListState>({
    selectedReseconId: undefined,
    isDetailModalOpen: false,
    isDeleteModalOpen: false,
    isDeleting: false,
  });
  const [deleteResecon] = useDeleteReseconMutation();

  const handleDeleteResecon = useCallback(async () => {
    if (!state.selectedReseconId) return;

    setState((prevState) => ({...prevState, isDeleting: true}));

    try {
      await deleteResecon(state.selectedReseconId).unwrap();
      showNotification(
        t('Success'),
        t('Successfully deleted!'),
        'bottomRight',
        'success',
      );
      onDeleteModalClose();
    } catch (error) {
      showNotification(
        t('Error'),
        t('An error occurred during the deletion!'),
        'bottomRight',
        'error',
      );
    } finally {
      setState((prevState) => ({...prevState, isDeleting: false}));
    }
  }, [state.selectedReseconId]);

  const onDetailModalOpen = useCallback(
    (reseconId?: number) => {
      setState({
        selectedReseconId: reseconId,
        isDetailModalOpen: true,
        isDeleteModalOpen: false,
        isDeleting: false,
      });
    },
    [setState],
  );

  const onDetailModalClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      selectedReseconId: undefined,
      isDetailModalOpen: false,
    }));
  }, [setState]);

  const onDeleteModalOpen = useCallback(
    (reseconId: number) => {
      setState({
        selectedReseconId: reseconId,
        isDetailModalOpen: false,
        isDeleteModalOpen: true,
        isDeleting: false,
      });
    },
    [setState],
  );

  const onDeleteModalClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      selectedReseconId: undefined,
      isDeleteModalOpen: false,
    }));
  }, [setState]);

  const actions: IUseReseconListActions = {
    onDeleteModalOpen,
    onDeleteModalClose,
    onDetailModalOpen,
    onDetailModalClose,
    handleDeleteResecon,
  };

  return [state, actions];
};
