import RegistrationInfo from '@app/pages/RegistrationInfo/RegistrationInfo';
import AuditHistory from '@app/pages/AuditHistory/AuditHistory';
import ManualInfo from '@app/pages/Manual/ManualInfo';
import FormInquiry from '@app/pages/Inquiry/Inquiry';
import PaymentHistory from '@app/pages/PaymentHistory/PaymentHistory';
import PaymentInfo from '@app/pages/PaymentInfo/PaymentInfo';
import PharmacyList from '@app/pages/Pharmacy/PharmacyList';
import ReseconList from '@app/pages/Resecon/ReseconList';
import UserManagement from '@app/pages/Staff/StaffList';
import Unsubscribe from '@app/pages/Unsubscribe/Unsubscribe';
import {EUserRole} from '@app/constants';
import {Routes} from '@app/router/Routes';
import {FC} from 'react';

type ComponentType = FC<Record<string, unknown>>;

export type MyRouteConfig = {
  path: string;
  exact?: boolean;
  Component: ComponentType;
  accessableRoles?: EUserRole[];
};

export const routerConfig: MyRouteConfig[] = [
  {
    path: Routes.PharmacyList,
    Component: PharmacyList,
    accessableRoles: [EUserRole.owner, EUserRole.companyManager],
  },
  {
    path: Routes.PaymentHistory,
    Component: PaymentHistory,
    accessableRoles: [EUserRole.owner, EUserRole.companyManager],
  },
  {
    path: Routes.PaymentInfo,
    Component: PaymentInfo,
    accessableRoles: [EUserRole.owner, EUserRole.companyManager],
  },
  {
    path: Routes.AuditHistory,
    Component: AuditHistory,
  },
  {
    path: Routes.AccountSettings,
    Component: RegistrationInfo,
    accessableRoles: [
      EUserRole.owner,
      EUserRole.companyManager,
      EUserRole.pharmacyManager,
    ],
  },
  {
    path: Routes.UserManagement,
    Component: UserManagement,
    accessableRoles: [EUserRole.owner, EUserRole.pharmacyManager],
  },
  {
    path: Routes.ReseconList,
    Component: ReseconList,
    accessableRoles: [EUserRole.owner, EUserRole.pharmacyManager],
  },
  {
    path: Routes.ManualInfo,
    Component: ManualInfo,
  },
  {
    path: Routes.FormInquiry,
    Component: FormInquiry,
  },
  {
    path: Routes.Unsubscribe,
    Component: Unsubscribe,
    accessableRoles: [EUserRole.owner],
  },
];
