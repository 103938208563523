import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Image, Typography} from 'antd';
import {Button, Checkbox, CheckboxProps, Col, Form, Row, Space} from 'antd';
import {CONSTANTS} from '@app/constants';
import {useRegistrationForm} from './useRegistrationForm';
import Loading from '@app/components/Loading/Loading';
import signUpImage from '@app/assets/sign_up.png';
import UserInfoCard from '@app/components/FormCard/UserInfoCard';
import PharmacyInfoCard from '@app/components/FormCard/PharmacyInfoCard';
import CompanyInfoCard from '@app/components/FormCard/CompanyInfoCard';
import MyBaseHeader from '@app/layout/header/MyBaseHeader';

const {Link} = Typography;

const Register: FC = () => {
  const {t} = useTranslation();
  const {form, initialValues, isLoading, onSubmit} = useRegistrationForm();
  const [isAgreeTermsChecked, setIsAgreeTermsChecked] = useState(false);

  const onChangeAgreeTerms: CheckboxProps['onChange'] = (e) => {
    setIsAgreeTermsChecked(e.target.checked);
  };

  return (
    <>
      {isLoading && <Loading />}
      <MyBaseHeader title={t('Sign Up')} />
      <div
        style={{
          width: '100vw',
          overflow: 'hidden',
        }}
      >
        <Row gutter={24}>
          <Col span={0} lg={6} offset={2}>
            <Image
              src={signUpImage}
              style={{
                height: 'auto',
                objectFit: 'cover',
              }}
            />
          </Col>
          <Col
            span={24}
            lg={14}
            style={{
              display: 'flex',
              justifyContent: 'start',
            }}
          >
            <Form
              form={form}
              onFinish={onSubmit}
              layout="vertical"
              initialValues={initialValues}
              autoComplete="off"
            >
              <Space direction="vertical">
                <UserInfoCard />
                <PharmacyInfoCard form={form} />
                <CompanyInfoCard form={form} />
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <Checkbox
                    value={isAgreeTermsChecked}
                    onChange={onChangeAgreeTerms}
                  >
                    <Link
                      href={CONSTANTS.PRIVACY_POLICY_URL}
                      target="_blank"
                      style={{textDecoration: 'underline'}}
                    >
                      {t('LINK TO PRIVACY POLICY')}
                    </Link>
                    {t('and')}
                    <Link
                      href={CONSTANTS.TERMS_OF_USAGE_URL}
                      target="_blank"
                      style={{textDecoration: 'underline'}}
                    >
                      {t('LINK TO TERMS OF USAGE')}
                    </Link>
                    {t('agree to terms of usage and privacy policy')}
                  </Checkbox>
                </Form.Item>
              </Space>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isAgreeTermsChecked}
                  className="ant-btn-lg ant-btn-block"
                >
                  {t('Register')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Register;
