import baseQueryWithReauth from '@app/helper/baseQueryWithReAuth';
import {TResecon, TReseconCreate, TReseconUpdate} from '@app/types';
import {createApi} from '@reduxjs/toolkit/query/react';

export const apiReseconService = createApi({
  reducerPath: 'reseconService',
  tagTypes: ['Resecon'],
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getResecons: builder.query<TResecon[], void>({
        query: () => '/receipt_computers',
        providesTags: (result) =>
          result
            ? [
                ...result.map(({id}) => ({
                  type: 'Resecon' as const,
                  id,
                })),
                'Resecon',
              ]
            : ['Resecon'],
      }),
      getReseconById: builder.query<TResecon, number>({
        query: (id) => `/receipt_computers/${id}`,
      }),
      createResecon: builder.mutation<TResecon, TReseconCreate>({
        query: (body) => ({
          url: '/receipt_computers',
          method: 'POST',
          body: body,
        }),
        invalidatesTags: [{type: 'Resecon'}],
      }),
      updateResecon: builder.mutation<TResecon, TReseconUpdate>({
        query: ({id, ...body}) => ({
          url: `/receipt_computers/${id}`,
          method: 'PATCH',
          body: body,
        }),
        invalidatesTags: (result, error, arg) => [
          {type: 'Resecon', id: arg.id},
        ],
      }),
      deleteResecon: builder.mutation<null, number>({
        query: (id) => ({
          url: `/receipt_computers/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [{type: 'Resecon'}],
      }),
    };
  },
});

export const {
  useGetReseconsQuery,
  useGetReseconByIdQuery,
  useLazyGetReseconByIdQuery,
  useCreateReseconMutation,
  useUpdateReseconMutation,
  useDeleteReseconMutation,
} = apiReseconService;
