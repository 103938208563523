import {useState} from 'react';
import {findAddress} from '@app/utils/geocodeApi';

const useGeolocation = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onSearch = async (zipCode: string) => {
    setIsLoading(true);
    const result = await findAddress(zipCode);
    setIsLoading(false);
    return result;
  };

  return {isLoading, onSearch};
};

export default useGeolocation;
