/**
 * Application layer of all constants
 */
export const CONSTANTS = {
  TOKEN_KEY: 'token_key',
  USER_INFO: 'USER_INFO',
  API_URL: window?._env_?.REACT_APP_API_URL || 'https://api.dev.okusuri-ai.com',
  HOME_PAGE_URL: 'https://www.okusuri-ai.com',
  PRIVACY_POLICY_URL: 'https://www.okusuri-ai.com/privacy-policy',
  TERMS_OF_USAGE_URL: 'https://www.okusuri-ai.com/use-of-agreement',
  CUSTOM_PLAN_URL: 'https://www.okusuri-ai.com/customplan',
  EVENT_PUBLIC_RELATION_STATIC:
    'https://okusuri-s3-global.s3.ap-northeast-1.amazonaws.com',
  APP_STORE_URL: 'https://apps.apple.com/jp/app/okusuri-ai/id1577707577',
  NEW_EMAIL_FORM_FIELD_NAME: 'new_email',
  NEW_EMAIL_CONFIRMATION_FORM_FIELD_NAME: 'new_email_confirmation',
  PASSWORD_FORM_FIELD_NAME: 'password',
  PASSWORD_CONFIRMATION_FORM_FIELD_NAME: 'password_confirmation',
  NEW_PASSWORD_FORM_FIELD_NAME: 'new_password',
  NEW_PASSWORD_CONFIRMATION_FORM_FIELD_NAME: 'new_password_confirmation',
};

export enum EManualType {
  app = 'app',
  web = 'web',
}

export enum EPaymentMethod {
  card = 'CARD',
  invoice = 'INVOICE',
}

export enum EPaymentInterval {
  monthly = 'MONTHLY',
  annual = 'ANNUAL',
}

export enum EUserRole {
  owner = 'OWNER',
  companyManager = 'COMPANY_MANAGER',
  pharmacyManager = 'PHARMACY_MANAGER',
  pharmacyStaff = 'PHARMACY_STAFF',
}

export enum EPharmacyStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  invited = 'INVITED',
  receiptComputerNotConnected = 'RECEIPT_COMPUTER_NOT_CONNECTED',
  passwordNotSet = 'PASSWORD_NOT_SET',
}

export enum EInvoiceDeliveryMethod {
  email = 'email',
  posting = 'posting',
}

export enum ECompanyStatus {
  DEMO = 'DEMO',
  UNDER_EXAMINATION = 'UNDER_EXAMINATION',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  ACTIVE = 'ACTIVE',
}

export enum EPaymentStatus {
  SCHEDULED = 'SCHEDULED',
  CHARGED = 'CHARGED',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  ISSUED = 'ISSUED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
}

export enum ErrorCodes {
  incorrectInputInformationError = 1001,
  alreadyLoggedInWithAnotherDeviceError = 1002,
  phoneNumberAlreadyRegisteredError = 1003,
  emailAlreadyRegisteredError = 1004,
  accountNotConfirmedError = 1005,
  accountAlreadyConfirmedError = 1006,
  passwordNotLongEnoughError = 1007,
  attemptLimitExceededError = 1008,
  invalidConfirmationCodeError = 1009,
  signUpConfirmationCodeExpiredError = 1010,
  confirmationCodeExpiredError = 1011,
  cardAlreadyRegisteredError = 1012,
  usernameAlreadyRegisteredError = 1014,
  paymentNotCompletedYetError = 1015,
  paymentAlreadyCompletedError = 1016,
  paymentFailedError = 1017,
  accountIsUnsubscribedError = 1018,
  accountIsDisabledError = 1019,
  forceUpdateRequiredError = 1023,
  facilityCodeAlreadyRegisteredError = 1024,
  invoiceInReviewError = 1025,
  auditAlreadyCreatedError = 1026,
  ipAddressAlreadyRegisteredError = 1027,
  companyNotUnderReviewError = 1028,
  limitedDueToDemoModeError = 1029,
  passwordAlreadySetError = 1030,
  companyUnderExaminationError = 1031,
  invalidAddressError = 1032,
  invalidEmailError = 1033,
  invalidPhoneNumberError = 1034,
  invalidZipCodeError = 1035,
  invalidWebsiteUrlError = 1036,
}

export enum EAuditStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  DELETE_SCHEDULED = 'DELETE_SCHEDULED',
}

export enum EMedicineStatus {
  VISUALLY_CHECKED = 'VISUALLY_CHECKED',
  AUDIT_NOT_REQUIRED = 'AUDIT_NOT_REQUIRED',
}

export enum EInvoiceStatus {
  UNEXAMINED = 'UNEXAMINED',
  PASSED = 'PASSED',
  REJECTED = 'REJECTED',
}
