import {
  useBatchCreateFileMutation,
  useBatchUpdateFileMutation,
  useSendInquiryMutation,
} from '@app/redux/services/accountService';
import {TInquiryCreate, TInquiryForm} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {Form} from 'antd';
import {useTranslation} from 'react-i18next';

export const useInquiryForm = () => {
  const {t} = useTranslation();
  const [sendInquiry, {isLoading: isSending}] = useSendInquiryMutation();
  const [batchCreateFile, {isLoading: isFileCreating}] =
    useBatchCreateFileMutation();
  const [batchUpdateFile, {isLoading: isFileUpdating}] =
    useBatchUpdateFileMutation();
  const [form] = Form.useForm<TInquiryForm>();

  const onSendInquiry = async () => {
    const values = await form.validateFields();
    try {
      const createInquiryPayload: TInquiryCreate = {
        text: values.title + '\n' + values.text,
      };
      const inquiry = await sendInquiry(createInquiryPayload).unwrap();
      const attachments = values.attachments?.fileList;

      if (attachments?.length > 0) {
        const createFilePayload = attachments.map((file) => ({
          path: 'inquiry' as const,
          name: file.name,
          mime_type: file.type,
          size: file.size,
        }));
        const uploadFiles = await batchCreateFile(createFilePayload).unwrap();

        await Promise.all(
          uploadFiles.map(({url}, index) => {
            const fileObject = attachments[index].originFileObj;
            const contentType = fileObject?.type;
            const headers = contentType
              ? {'Content-Type': contentType}
              : undefined;

            return fetch(url, {
              method: 'PUT',
              body: fileObject,
              headers,
            });
          }),
        );
        await batchUpdateFile(
          uploadFiles.map(({id}) => ({
            id,
            parent_id: inquiry.id,
          })),
        ).unwrap();
      }

      showNotification(
        t('Success'),
        t('Your inquiry has been sent.'),
        'bottomRight',
        'success',
      );
      form.resetFields();
    } catch (error) {
      showNotification(
        t('Error'),
        t('An error occurred while sending your inquiry.'),
        'bottomRight',
        'error',
      );
    }
  };
  return {
    form,
    onSendInquiry,
    isLoading: isSending || isFileCreating || isFileUpdating,
  };
};
