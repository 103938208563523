import {useDeleteStaffMutation} from '@app/redux/services/staffService';
import {TStaff} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {TableProps} from 'antd';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

interface IUseStaffListState {
  selectedStaffId: number | undefined;
  isDetailModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isDeleting: boolean;
}

interface IUseStaffListActions {
  onDeleteModalOpen: (staffId: number) => void;
  onDeleteModalClose: () => void;
  onDetailModalOpen: (staffId?: number) => void;
  onDetailModalClose: () => void;
  handleDeleteStaff: () => Promise<void>;
  onTableChange: TableProps<TStaff>['onChange'];
}

export const useStaffList = (): [IUseStaffListState, IUseStaffListActions] => {
  const {t} = useTranslation();
  const [state, setState] = useState<IUseStaffListState>({
    selectedStaffId: undefined,
    isDetailModalOpen: false,
    isDeleteModalOpen: false,
    isDeleting: false,
  });
  const [deleteStaff] = useDeleteStaffMutation();
  const [, setSearchParams] = useSearchParams();

  const onTableChange: TableProps<TStaff>['onChange'] = (pagination) => {
    const {current, pageSize} = pagination;
    setSearchParams({
      current: String(current),
      pageSize: String(pageSize),
    });
  };

  const handleDeleteStaff = useCallback(async () => {
    if (!state.selectedStaffId) return;

    setState((prevState) => ({...prevState, isDeleting: true}));

    try {
      await deleteStaff(state.selectedStaffId).unwrap();
      showNotification(
        t('Success'),
        t('Successfully deleted!'),
        'bottomRight',
        'success',
      );
      onDeleteModalClose();
    } catch (error) {
      showNotification(
        t('Error'),
        t('An error occurred during the deletion!'),
        'bottomRight',
        'error',
      );
    } finally {
      setState((prevState) => ({...prevState, isDeleting: false}));
    }
  }, [state.selectedStaffId]);

  const onDetailModalOpen = useCallback(
    (staffId?: number) => {
      setState({
        selectedStaffId: staffId,
        isDetailModalOpen: true,
        isDeleteModalOpen: false,
        isDeleting: false,
      });
    },
    [setState],
  );

  const onDetailModalClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      selectedStaffId: undefined,
      isDetailModalOpen: false,
    }));
  }, [setState]);

  const onDeleteModalOpen = useCallback(
    (staffId: number) => {
      setState({
        selectedStaffId: staffId,
        isDetailModalOpen: false,
        isDeleteModalOpen: true,
        isDeleting: false,
      });
    },
    [setState],
  );

  const onDeleteModalClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      selectedStaffId: undefined,
      isDeleteModalOpen: false,
    }));
  }, []);

  const actions: IUseStaffListActions = {
    onDeleteModalOpen,
    onDeleteModalClose,
    onDetailModalOpen,
    onDetailModalClose,
    handleDeleteStaff,
    onTableChange,
  };

  return [state, actions];
};
