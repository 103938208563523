import Logo from '@app/components/Logo/Logo';
import {Button, Col, Layout, Row, Space, Tag, Typography} from 'antd';
import {FC} from 'react';
import {useIsDemo} from '@app/hooks/useIsDemo';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Routes} from '@app/router/Routes';
import User from '@app/components/User/User';

const {Header} = Layout;
const {Title} = Typography;

interface IMyHeaderProps {
  title?: string;
}

const MyHeader: FC<IMyHeaderProps> = ({title}) => {
  const {t} = useTranslation();
  const isDemo = useIsDemo();
  const navigate = useNavigate();
  const HeaderLogo = (
    <Space>
      <Row gutter={24} style={{display: 'flex', alignItems: 'center'}}>
        <Col span={24} lg={isDemo ? 12 : 24}>
          <Logo />
        </Col>
        <Col span={10} lg={4}>
          {isDemo && <Tag color="blue">{t('Demo version')}</Tag>}
        </Col>
        <Col span={0} lg={4}>
          {isDemo && (
            <Button
              type="primary"
              onClick={() => {
                navigate(Routes.PaymentInfo);
              }}
            >
              {t('Purchase the full version')}
            </Button>
          )}
        </Col>
      </Row>
    </Space>
  );

  return (
    <Header className="header">
      <Row align="middle" justify="space-between">
        <Col span={8}>{HeaderLogo}</Col>
        <Col span={8} style={{display: 'flex', justifyContent: 'center'}}>
          <Title level={5} className="title-text">
            {title}
          </Title>
        </Col>
        <Col span={8} style={{display: 'flex', justifyContent: 'flex-end'}}>
          <User />
        </Col>
      </Row>
    </Header>
  );
};

export default MyHeader;
