import axios from 'axios';

export const findAddress = async (zipCode: string): Promise<string> => {
  const baseUrl = 'https://zipcloud.ibsnet.co.jp/api/search';
  const url = `${baseUrl}?zipcode=${zipCode}`;
  const address = await axios
    .get(url)
    .then((response) => {
      const result = response.data.results[0];
      return `${result.address1}${result.address2}${result.address3}`;
    })
    .catch((error) => {
      // TODO: Handle this error
      console.error(error);
      return '';
    });

  return address;
};
