import {useResetPasswordMutation} from '@app/redux/services/userService';
import {Routes} from '@app/router/Routes';
import {TError, TResetPassword} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {Form} from 'antd';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';

const useForgotPasswordConfirmForm = () => {
  const {t} = useTranslation();
  const [resetPassword, {isLoading}] = useResetPasswordMutation();
  const [form] = Form.useForm<TResetPassword>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onFinish = async () => {
    const values = await form.validateFields();
    try {
      await resetPassword(values).unwrap();
      navigate(Routes.Login);
    } catch (error) {
      const {data: errorData} = error as TError;
      showNotification(t('Error'), errorData?.message, 'bottomRight', 'error');
    }
  };

  const initialValues = useMemo(() => {
    return {
      email: searchParams.get('email') || '',
      confirmation_code: searchParams.get('confirmation_code') || '',
      password: '',
    };
  }, [searchParams]);

  return {
    form,
    initialValues,
    onFinish,
    isLoading,
  };
};

export default useForgotPasswordConfirmForm;
