import {Modal} from 'antd';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
interface IConfirmModalProps {
  open: boolean;
  okText?: string;
  cancelText?: string;
  text?: string;
  title?: React.ReactNode;
  onConfirm: () => Promise<void> | void;
  onCancel: () => void;
}
const ConfirmActionModal: FC<IConfirmModalProps> = ({
  open = false,
  onConfirm,
  onCancel,
  title,
  text,
  okText,
  cancelText,
}) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const {t} = useTranslation();
  const onOk = async () => {
    setConfirmLoading(true);
    await onConfirm();
    setConfirmLoading(false);
  };

  return (
    <Modal
      closable={false}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      okText={okText || t('Delete')}
      cancelText={cancelText || t('Cancel')}
      bodyStyle={{textAlign: 'center', alignContent: 'center'}}
      centered
    >
      <p>{text}</p>
    </Modal>
  );
};
export default ConfirmActionModal;
