import {LogoutOutlined} from '@ant-design/icons';
import {EUserRole} from '@app/constants';
import {useUser} from '@app/hooks/useUser';
import {getToken} from '@app/middleware/getToken';
import {useAppDispatch} from '@app/redux/reduxHooks';
import {clearUser, logout} from '@app/redux/slices/userSlice';
import {Routes} from '@app/router/Routes';
import {Button, Space, Typography} from 'antd';
import {FC} from 'react';

import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const {Text} = Typography;

const User: FC = () => {
  const {t} = useTranslation();
  const user = useUser();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onLogout = () => {
    const token = getToken();
    dispatch(logout({...token}));
    dispatch(clearUser());
    navigate(Routes.Login);
  };

  const getUserRole = () => {
    switch (user?.role) {
      case EUserRole.owner || EUserRole.companyManager:
        return t('Admin');
      case EUserRole.pharmacyManager:
        return t('Pharmacy admin');
      default:
        return '';
    }
  };

  if (user?.id)
    return (
      <>
        <Space size={24}>
          <Text style={{fontSize: 'large'}} className="btn-text">
            {user.full_name}
          </Text>
          {user?.role !== EUserRole.pharmacyStaff && (
            <Text
              style={{fontSize: 'large', fontWeight: '800'}}
              className="btn-text"
            >
              {getUserRole()}
            </Text>
          )}
          <Button icon={<LogoutOutlined />} onClick={onLogout}>
            <Text className="btn-text">{t('Logout')}</Text>
          </Button>
        </Space>
      </>
    );
  return <></>;
};

export default User;
