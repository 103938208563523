import {ErrorCodes} from '@app/constants';
import {
  useCreateStaffMutation,
  useLazyGetStaffByIdQuery,
  useUpdateStaffMutation,
} from '@app/redux/services/staffService';
import {TError, TStaffCreate, TStaffUpdate} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {Form} from 'antd';
import {t} from 'i18next';
import {useEffect} from 'react';

export interface IUseStaffForm {
  staffId?: number;
  onCancel: () => void;
}

export const useStaffForm = ({staffId, onCancel}: IUseStaffForm) => {
  const [form] = Form.useForm<TStaffCreate | TStaffUpdate>();
  const [createStaff, {isLoading: isCreatingStaff}] = useCreateStaffMutation();
  const [updateStaff, {isLoading: isUpdatingStaff}] = useUpdateStaffMutation();
  const [getStaffById] = useLazyGetStaffByIdQuery();

  useEffect(() => {
    const setInitialValues = async () => {
      if (!staffId) return;
      const staff = await getStaffById(staffId).unwrap();
      form.setFieldsValue(staff);
    };
    setInitialValues();

    return () => {
      if (!staffId) return;
      form.resetFields();
    };
  }, [staffId]);

  const onSubmit = async () => {
    const values = await form.validateFields();

    try {
      if (staffId) {
        await updateStaff(values as TStaffUpdate).unwrap();
      } else {
        await createStaff(values as TStaffCreate).unwrap();
      }
      const successMessage = staffId
        ? t('Successfully updated!')
        : t('Successfully registered!');
      showNotification(t('Success'), successMessage, 'bottomRight', 'success');
      form.resetFields();
      onCancel();
    } catch (error) {
      const {data: errorData} = error as TError;
      const errorMessage = [
        ErrorCodes.usernameAlreadyRegisteredError,
        ErrorCodes.limitedDueToDemoModeError,
      ].includes(errorData?.error_code)
        ? errorData.message
        : staffId
          ? t('An error occurred during the update!')
          : t('An error occurred during the registration!');
      showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
    }
  };

  return {
    form,
    onSubmit,
    isLoading: isCreatingStaff || isUpdatingStaff,
  };
};
