import {TError, TReseconCreate, TReseconUpdate} from '@app/types';
import {
  useCreateReseconMutation,
  useLazyGetReseconByIdQuery,
  useUpdateReseconMutation,
} from '@app/redux/services/reseconService';
import showNotification from '@app/utils/showNotification';
import {Form} from 'antd';
import {t} from 'i18next';
import {useEffect} from 'react';
import {ErrorCodes} from '@app/constants';

export interface IUseReseconForm {
  reseconId?: number;
  onCancel: () => void;
}

export const useReseconForm = ({reseconId, onCancel}: IUseReseconForm) => {
  const [form] = Form.useForm<TReseconCreate | TReseconUpdate>();
  const [createResecon, {isLoading: isCreatingResecon}] =
    useCreateReseconMutation();
  const [updateResecon, {isLoading: isUpdatingResecon}] =
    useUpdateReseconMutation();
  const [getReseconById] = useLazyGetReseconByIdQuery();

  useEffect(() => {
    const setInitialValues = async () => {
      if (!reseconId) return;
      const resecon = await getReseconById(reseconId).unwrap();
      form.setFieldsValue(resecon);
    };
    setInitialValues();

    return () => {
      if (!reseconId) return;
      form.resetFields();
    };
  }, [reseconId]);

  const onSubmit = async () => {
    const values = await form.validateFields();
    try {
      if (reseconId) {
        await updateResecon(values as TReseconUpdate).unwrap();
      } else {
        await createResecon(values as TReseconCreate).unwrap();
      }
      const successMessage = reseconId
        ? t('Successfully updated!')
        : t('Successfully registered!');
      showNotification(t('Success'), successMessage, 'bottomRight', 'success');
      form.resetFields();
      onCancel();
    } catch (error) {
      const {data: errorData} = error as TError;
      const errorMessage = [
        ErrorCodes.ipAddressAlreadyRegisteredError,
        ErrorCodes.limitedDueToDemoModeError,
      ].includes(errorData?.error_code)
        ? errorData.message
        : reseconId
          ? t('An error occurred during the update!')
          : t('An error occurred during the registration!');
      showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
    }
  };

  return {
    form,
    isLoading: isCreatingResecon || isUpdatingResecon,
    onSubmit,
  };
};
