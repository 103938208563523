import {ErrorCodes} from '@app/constants';
import usePasswordSearchParams from '@app/hooks/usePasswordSearchParams';
import {useCompletePharmacyMutation} from '@app/redux/services/userService';
import {Routes} from '@app/router/Routes';
import {TError, TCompletePharmacy} from '@app/types';
import showNotification from '@app/utils/showNotification';
import {Form} from 'antd';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const useCompletePharmacyInvitationForm = () => {
  const {t} = useTranslation();
  const [form] = Form.useForm<TCompletePharmacy>();
  const [completePharmacy, {isLoading}] = useCompletePharmacyMutation();
  const {cognitoUsername, email, temporaryPassword} = usePasswordSearchParams();
  const navigate = useNavigate();

  const onFinish = async () => {
    const values = await form.validateFields();

    try {
      await completePharmacy({
        ...values,
        cognito_username: cognitoUsername,
        temporary_password: temporaryPassword,
      }).unwrap();
      navigate(Routes.Login);
    } catch (error) {
      const {data: errorData} = error as TError;
      const errorMessage = [
        ErrorCodes.facilityCodeAlreadyRegisteredError,
        ErrorCodes.phoneNumberAlreadyRegisteredError,
      ].includes(errorData?.error_code)
        ? errorData.message
        : t('An error occurred during the registration!');
      showNotification(t('Error'), errorMessage, 'bottomRight', 'error');
    }
  };

  const initialValues = useMemo(() => {
    return {
      email,
    };
  }, [email]);

  return {
    form,
    initialValues,
    isLoading,
    onFinish,
  };
};

export default useCompletePharmacyInvitationForm;
