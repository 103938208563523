import {EAuditStatus} from '@app/constants';
import baseQueryWithReauth from '@app/helper/baseQueryWithReAuth';
import {
  TAuditDetail,
  TAuditList,
  TFilterArgs,
  TPaginationArgs,
  TSortByArgs,
} from '@app/types';
import {createApi} from '@reduxjs/toolkit/query/react';

export const auditService = createApi({
  reducerPath: 'auditService',
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      fetchAudits: builder.query<
        TAuditList,
        TPaginationArgs & TSortByArgs & TFilterArgs
      >({
        query(args) {
          return {
            url: '/audits',
            params: {
              ...args,
              status: EAuditStatus.COMPLETED,
            },
          };
        },
      }),
      getAuditById: builder.query<TAuditDetail, number>({
        query(auditId) {
          return `/audits/${auditId}`;
        },
      }),
    };
  },
});

export const {useFetchAuditsQuery, useGetAuditByIdQuery} = auditService;
