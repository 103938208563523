import {Result} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import MyHeader from '@app/layout/header/MyHeader';

const NotFound: FC<unknown> = () => {
  const {t} = useTranslation();
  return (
    <>
      <MyHeader />
      <Result
        status={'404'}
        title={t('notFoundTitle')}
        subTitle={t('notFoundSubTitle')}
      />
    </>
  );
};

export default NotFound;
