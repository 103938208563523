import '@app/i18n';
import App from '@app/Root';
import {ConfigProvider} from 'antd';
import jaJP from 'antd/es/locale/ja_JP';
import React from 'react';
import {createRoot} from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ConfigProvider
      locale={jaJP}
      theme={{
        token: {
          colorPrimary: '#0665ac',
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>,
);
reportWebVitals();
