import {CONSTANTS} from '@app/constants';
import {Rule} from 'antd/es/form';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {validationRegex} from './validationRegex';

interface IRuleArgs {
  isRequired?: boolean;
  isLabelAliased?: boolean;
}

interface IPatternRuleArgs {
  pattern: RegExp;
  isLabelAliased?: boolean;
}

export const useFormValidationRules = () => {
  const {t} = useTranslation();

  const conditionalRequiredRule = (args?: IRuleArgs): Rule => {
    const {isRequired, isLabelAliased} = args || {};
    return {
      required: isRequired !== undefined ? isRequired : true,
      whitespace: isRequired !== undefined ? isRequired : false,
      message: isLabelAliased
        ? t('${labelAlias} is required')
        : t('${label} is required'),
    };
  };

  const patternRule = (args: IPatternRuleArgs): Rule => {
    const {pattern, isLabelAliased} = args || {};
    return {
      pattern: pattern,
      message: isLabelAliased
        ? t('Please enter a valid ${labelAlias}')
        : t('Please enter a valid ${label}'),
    };
  };

  const passwordConfirmation =
    (passwordFieldName: string): Rule =>
    ({getFieldValue}) => ({
      validator(_, value) {
        const password = getFieldValue(passwordFieldName);
        if (!value || password === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(t('${label} does not match with ${labelMatch}')),
        );
      },
    });

  const emailConfirmation =
    (emailFieldName: string): Rule =>
    ({getFieldValue}) => ({
      validator(_, value) {
        const email = getFieldValue(emailFieldName);
        if (!value || email === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t('New Emails do not match')));
      },
    });

  const requiredOnly = (args?: IRuleArgs): Rule[] => {
    return [conditionalRequiredRule(args)];
  };

  const passwordRules = (args?: IRuleArgs): Rule[] => {
    return [
      conditionalRequiredRule(args),
      patternRule({
        pattern: validationRegex.password,
        isLabelAliased: args?.isLabelAliased,
      }),
      {
        min: 8,
        message: t('Please enter at least 8 characters'),
      },
    ];
  };

  const passwordConfirmationRules = (args?: IRuleArgs): Rule[] => {
    return [
      conditionalRequiredRule(args),
      passwordConfirmation(CONSTANTS.PASSWORD_FORM_FIELD_NAME),
    ];
  };

  const emailRules = (args?: IRuleArgs): Rule[] => {
    return [
      conditionalRequiredRule(args),
      {
        type: 'email',
        message: t('Please enter a valid email address'),
      },
    ];
  };

  const emailConfirmationRules = (args?: IRuleArgs): Rule[] => {
    return [
      conditionalRequiredRule(args),
      {
        type: 'email',
        message: t('Please enter a valid email address'),
      },
      emailConfirmation(CONSTANTS.NEW_EMAIL_FORM_FIELD_NAME),
    ];
  };

  const katakanaRules = (args?: IRuleArgs): Rule[] => {
    return [
      conditionalRequiredRule(args),
      patternRule({
        pattern: validationRegex.katakana,
        isLabelAliased: args?.isLabelAliased,
      }),
    ];
  };

  const facilityCodeRules = (args?: IRuleArgs): Rule[] => {
    return [
      conditionalRequiredRule(args),
      {
        pattern: validationRegex.facilityCode,
        message: t('Please input a number with at least 7 digits'),
      },
    ];
  };

  const phoneNumberRules = (args?: IRuleArgs): Rule[] => {
    return [
      conditionalRequiredRule(args),
      {
        pattern: validationRegex.phoneNumber,
        message: t('Please input a number with at least 10 digits'),
      },
    ];
  };

  const zipCodeRules = (args?: IRuleArgs): Rule[] => {
    return [
      conditionalRequiredRule(args),
      {
        pattern: validationRegex.zipCode,
        message: t('Please enter a 7-digit number'),
      },
    ];
  };

  const ipAddressRules = (args?: IRuleArgs): Rule[] => {
    return [
      conditionalRequiredRule(args),
      patternRule({
        pattern: validationRegex.ipAddress,
        isLabelAliased: args?.isLabelAliased,
      }),
    ];
  };

  const confirmationCodeRules = (args?: IRuleArgs): Rule[] => {
    return [
      conditionalRequiredRule(args),
      {
        pattern: validationRegex.confirmationCode,
        message: t('Please enter a 6-digit number'),
      },
    ];
  };

  const websiteRules = (args?: IRuleArgs): Rule[] => {
    return [
      conditionalRequiredRule(args),
      {
        pattern: validationRegex.website,
        message: t('Please enter a valid URL'),
      },
    ];
  };

  return useMemo(
    () => ({
      requiredOnly,
      passwordConfirmation,
      emailRules,
      emailConfirmationRules,
      katakanaRules,
      facilityCodeRules,
      phoneNumberRules,
      zipCodeRules,
      passwordRules,
      passwordConfirmationRules,
      confirmationCodeRules,
      ipAddressRules,
      websiteRules,
    }),
    [],
  );
};

export const useInputMaxLength = () => {
  const inputMaxLength = {
    fullName: 50,
    fullNameKana: 50,
    email: 50,
    department: 20,
    position: 20,
    companyName: 50,
    pharmacyName: 50,
    facilityCode: 10,
    phoneNumber: 17,
    zipCode: 7,
    location: 100,
    street: 100,
    ipAddress: 15,
    website: 100,
    receiptComputerName: 50,
    username: 50,
    shareName: 50,
    sharePathName: 100,
    password: 50,
    confirmationCode: 6,
  };

  return inputMaxLength;
};
