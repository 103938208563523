import {useGetPaymentsQuery} from '@app/redux/services/paymentService';
import {usePaymentList} from './usePaymentList';
import {Row, Table, Tag} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {EPaymentInterval, EPaymentStatus} from '@app/constants';

const PaymentHistory: FC = () => {
  const {t} = useTranslation();
  const {onTableChange, searchParams, setSearchParams} = usePaymentList();

  const {data: paymentHistory, isLoading} = useGetPaymentsQuery({
    limit: Number(searchParams.get('pageSize') ?? 10),
    page: Number(searchParams.get('current') ?? 1),
  });

  const columns = [
    {
      title: t('Payment Date'),
      dataIndex: 'start_date',
      key: 'start_date',
      render: (start_date: string) => {
        const date = new Date(start_date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return (
          <span>
            {year}
            {t('year')}
            {month.toString().padStart(2, '0')}
            {t('month')}
            {day.toString().padStart(2, '0')}
            {t('day')}
          </span>
        );
      },
    },
    {
      title: t('Payment Amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => (
        <span>
          {amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          {t('Yen')}
        </span>
      ),
    },
    {
      title: t('Payment Service Name'),
      dataIndex: 'interval',
      key: 'method',
      render: (_text: unknown, record: {interval: EPaymentInterval}) => {
        switch (record.interval) {
          case EPaymentInterval.monthly:
            return <span>{t('Payment Service Name Monthly')}</span>;
          default:
            return <span>{t('Payment Service Name Annually')}</span>;
        }
      },
    },
    {
      title: t('Payment Status'),
      key: 'status',
      render: (_text: unknown, record: {status: EPaymentStatus}) => {
        switch (record.status) {
          case EPaymentStatus.CHARGED:
            return <Tag color="green">{t('Payment Status Charged')}</Tag>;
          case EPaymentStatus.SCHEDULED:
            return <Tag color="blue">{t('Payment Status Scheduled')}</Tag>;
          case EPaymentStatus.CONFIRMED:
            return <Tag color="blue">{t('Payment Status Confirmed')}</Tag>;
          case EPaymentStatus.ISSUED:
            return <Tag color="gold">{t('Payment Status Issued')}</Tag>;
          case EPaymentStatus.PENDING:
            return <Tag color="gold">{t('Payment Status Pending')}</Tag>;
          case EPaymentStatus.CANCELLED:
            return <Tag color="gold">{t('Payment Status Cancelled')}</Tag>;
          case EPaymentStatus.REFUNDED:
            return <Tag color="gold">{t('Payment Status Refunded')}</Tag>;
          default:
            return <Tag color="red">{t('Payment Status Failed')}</Tag>;
        }
      },
    },
    // {
    //   title: t('payment.download.title'),
    //   key: 'button',
    //   render: (
    //     _text: unknown,
    //     record: {status: EPaymentStatus; id: number},
    //   ) => (
    //     <Button
    //       type="primary"
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         download(record.id);
    //       }}
    //       disabled={![EPaymentStatus.CHARGED].includes(record.status)}
    //     >
    //       {t('payment.buttons.download')}
    //     </Button>
    //   ),
    // },
  ];
  return (
    <Row gutter={[8, 8]} justify={'end'}>
      <Table
        style={{width: '100%'}}
        dataSource={paymentHistory?.data}
        columns={columns}
        pagination={{
          total: paymentHistory?.meta.total_items,
          pageSize: paymentHistory?.meta.items_per_page,
          onChange(page, pageSize) {
            setSearchParams({
              current: String(page),
              pageSize: String(pageSize),
            });
          },
          current: Number(searchParams.get('page') ?? '1'),
          position: ['bottomRight'],
          hideOnSinglePage: false,
        }}
        loading={isLoading}
        rowKey={(item) => item.id}
        onChange={onTableChange}
      />
    </Row>
  );
};

export default PaymentHistory;
