import Loading from '@app/components/Loading/Loading';
import {Button, Form, Input} from 'antd';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import useSetPasswordForm from './useSetPasswordForm';
import {MailOutlined, LockOutlined} from '@ant-design/icons';
import {
  useFormValidationRules,
  useInputMaxLength,
} from '@app/utils/formValidationRules';
import {CONSTANTS} from '@app/constants';
import AuthLogo from '@app/components/Logo/AuthLogo';

const SetPassword: FC = () => {
  const {t} = useTranslation();

  const {form, initialValues, onSubmit, isLoading} = useSetPasswordForm();
  const {passwordRules, passwordConfirmationRules} = useFormValidationRules();
  const inputMaxLength = useInputMaxLength();

  const content = (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onSubmit}
      autoComplete="off"
      labelCol={{span: 0}}
      wrapperCol={{span: 24}}
    >
      <Form.Item name="email" label={t('Email')}>
        <Input
          maxLength={inputMaxLength.email}
          prefix={<MailOutlined />}
          placeholder={t('Email')}
          disabled
        />
      </Form.Item>
      <Form.Item
        name={CONSTANTS.PASSWORD_FORM_FIELD_NAME}
        label={t('Password')}
        messageVariables={{labelAlias: t('Password')}}
        rules={passwordRules({isRequired: true, isLabelAliased: true})}
      >
        <Input.Password
          maxLength={inputMaxLength.password}
          prefix={<LockOutlined />}
          placeholder={t('Password')}
        />
      </Form.Item>
      <Form.Item
        name={CONSTANTS.PASSWORD_CONFIRMATION_FORM_FIELD_NAME}
        label={t('Password confirmation')}
        messageVariables={{labelMatch: t('Password')}}
        rules={passwordConfirmationRules({
          isRequired: true,
        })}
      >
        <Input.Password
          maxLength={inputMaxLength.password}
          prefix={<LockOutlined />}
          placeholder={t('Password confirmation')}
        />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        className="ant-btn-lg ant-btn-block"
      >
        {t('Confirm')}
      </Button>
    </Form>
  );

  return (
    <>
      {isLoading && <Loading />}
      <AuthLogo
        title={t('Setup password screen')}
        text={t('Please set a password')}
        content={content}
      />
    </>
  );
};

export default SetPassword;
